import React from "react";
import Meta from "../components/Meta";
import ScraperDashboardSection from "../components/scraper/ScraperDashboardSection";
import { requireAuth } from "../util/auth";

function ScraperDashboard(props) {
  return (
    <>
      <Meta title="Dashboard" />
      <ScraperDashboardSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="广告分析"
        subtitle="Dashboard"
      />
    </>
  );
}

export default requireAuth(ScraperDashboard);
