import React, { useContext, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Button, Dialog, Divider, Slide } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import '../../style/container.css'
import { Toaster, toast } from "react-hot-toast";
import { useAuth } from "../../util/auth";
import { chatContext } from "../../context/ChatContext"
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
// import TextToSpeechItems from "./elevenlabs/TextToSpeechItems";
import { IdIdentifierGeneral } from "./../functionalComponent/IdIdentifier";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "885px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
  },
  ay: {
  },
  copy: {
    display: ({ typing, load, error }) => typing || load || error ? "none" : "block",
    cursor: "pointer",
    "& img": { width: "22px" },
  },
  rotatedButton: {
    // transform: "rotate(-90deg)",
    padding: theme.spacing(2),
    fontSize: '1rem',
  },
  dialog: {
    // alignItems: "flex-end !important",
    maxHeight: "95vh",
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  audioPlayer: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  voiceDescription: {
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },
}));

function TextToSpeechSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [audioUrls, setAudioUrls] = useState([]);
  const [error, setError] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState("JBFqnCBsd6RMkjVDRZzb"); // Default to George
  const [voices, setVoices] = useState([]);
  const MAX_AUDIO_HISTORY = 5;

  // Fetch available voices when component mounts
  useEffect(() => {
    const fetchVoices = async () => {
      try {
        const response = await fetch('https://api.elevenlabs.io/v1/voices');
        const data = await response.json();
        setVoices(data.voices);
      } catch (err) {
        console.error('Failed to fetch voices:', err);
        toast.error('Failed to load available voices');
      }
    };

    fetchVoices();
  }, []);

  const handleTextToSpeech = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // console.log('Selected Voice ID:', selectedVoice);
      // https://ctg-api.onrender.com/textToSpeech
      // http://localhost:5001/textToSpeech
      const response = await fetch('https://ctg-api.onrender.com/textToSpeech', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          text,
          voiceId: selectedVoice
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to convert text to speech');
      }

      const audioBlob = await response.blob();
      const url = URL.createObjectURL(audioBlob);
      setAudioUrls(prev => {
        const newUrls = [...prev, { url, text, timestamp: new Date().toISOString() }];
        // Keep only the last MAX_AUDIO_HISTORY items
        return newUrls.slice(-MAX_AUDIO_HISTORY);
      });    
    } catch (err) {
      setError(err.message);
      toast.error('Failed to convert text to speech');
    } finally {
      setLoading(false);
    }
  };

  // Clean up when component unmounts
  useEffect(() => {
    return () => {
      audioUrls.forEach(audio => URL.revokeObjectURL(audio.url));
    };
  }, []);

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Card>
          <CardContent>
            <FormControl className={classes.formControl}>
              <InputLabel>Select Voice</InputLabel>
              <Select
                value={selectedVoice}
                onChange={(e) => setSelectedVoice(e.target.value)}
              >
                {voices.map((voice) => (
                  <MenuItem key={voice.voice_id} value={voice.voice_id}>
                    <div>
                      <div>{voice.name}</div>
                      <div className={classes.voiceDescription}>
                        {voice.labels.accent} • {voice.labels.description} • {voice.labels.age}
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              className={classes.textField}
              multiline
              minRows={4}
              maxRows={6}
              variant="outlined"
              label="Enter text to convert to speech"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            
            <Button
              variant="contained"
              color="primary"
              onClick={handleTextToSpeech}
              disabled={loading || !text}
            >
              {loading ? <CircularProgress size={24} /> : 'Convert to Speech'}
            </Button>

            {audioUrls.length > 0 && (
              <Box sx={{ mt: 2 }}>
                {[...audioUrls].reverse().map((audio, index) => (
                  <Box key={audio.timestamp} sx={{ mb: 2, p: 2, border: '1px solid #eee', borderRadius: 1 }}>
                    <Typography variant="caption" display="block" gutterBottom>
                      {new Date(audio.timestamp).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                      {audio.text.substring(0, 50)}...
                    </Typography>
                    <audio className={classes.audioPlayer} controls>
                      <source src={audio.url} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  </Box>
                ))}
              </Box>
            )}

            {error && (
              <Typography color="error" style={{ marginTop: '1rem' }}>
                {error}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Container>
      <Toaster position="bottom-center" />
    </Section>
  );
}

export default TextToSpeechSection;