import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Add } from '@material-ui/icons';
import botIcon from '../../assets/ctg.ico';
import Loader from './Loader';
import CopyButton from './CopyButton';
import { IdIdentifierGeneral } from "./IdIdentifier";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  message: {
    maxHeight: '8.5em',
    overflowY: 'auto',
    whiteSpace: 'pre-wrap',
    resize: 'none',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editor: {
    width: '100%',
    height: '80%',
    overflowY: 'auto',
  },
  boxes:{
    flex: 1,
  },
  gridPadding: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
}));

function ChatStripe({ isAi, value, uniqueId, adda_id, pepc_id, showIcon = true, loading = false, noCopy = false, combined, handleSubmit, handleAppend }) {
  const classes = useStyles();
  const [typedValue, setTypedValue] = useState("");

  useEffect(() => {
    if (loading) return;
  
    let i = 0;
    
    // Reset typedValue here only if combined is true
    if (combined) {
      setTypedValue("");
    }
  
    const typingInterval = setInterval(() => {
      if (i < value.length) {
        setTypedValue((typedValue) => typedValue + value[i]);
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, 5); // speed of typing can be adjusted here
  
    return () => {
      clearInterval(typingInterval);
    };
  }, [loading, value, combined]);

  // Render different types of values
  let renderValue;
  if(combined) {
    renderValue = () => {
      if (typeof value === "string") {
        return (
          <div className={classes.container}>
            <div className={classes.boxes}>
              <Typography variant="h6">{IdIdentifierGeneral({ id: adda_id || pepc_id })}</Typography>
              <div className={classes.message} id={uniqueId}>{typedValue}</div>
            </div>
            
            <div className={classes.buttons}>
              <Tooltip title="Regenerate">
                <Button id={adda_id} onClick={() => handleSubmit([adda_id])}>
                  <AutorenewIcon />
                </Button>
              </Tooltip>

              <Tooltip title="Append">
                <Button onClick={() => handleAppend(typedValue)}>
                  <Add />
                </Button>
              </Tooltip>
              
              <Tooltip title="Copy original text">
                <CopyButton content={value}/>
              </Tooltip>
            </div>
          </div>
        );
      }
    };
  }else{
    renderValue = () => {
      if (typeof value === "string") {
        return <div className="message" id={uniqueId}>{typedValue}</div>;
      } else if (React.isValidElement(value)) {
        return <div className="message" id={uniqueId}>{value}</div>;
      } else {
        return null;
      }
    };
  }

  return (
    <Box className={`wrapper ${isAi ? 'ai' : ''}`}>
      {combined ? (
        <div className="chat_combined">
        <Grid container>
          {/* <Grid item xs="auto">
            {showIcon && (
              <div className="profile">
                <img src={botIcon} alt={isAi ? "bot-icon" : "user-icon"} />
              </div>
            )}
          </Grid> */}
          <Grid item xs className={classes.gridPadding}>
            {loading ? (
              <Loader />
            ) : (
              renderValue()
            )}
          </Grid>
        </Grid>
        </div>
      ) : (
        <div className="chat">
          {showIcon && (
            <div className="profile">
              <img src={botIcon} alt={isAi ? "bot-icon" : "user-icon"} />
            </div>
          )}
          {loading ? (
            <Loader />
          ) : (
            renderValue()
          )}
          {loading ? (
            <></>
          ) : (
            noCopy ? <></> : <CopyButton content={value}/>
          )}
        </div>
      )}
    </Box>
  );
}

export default ChatStripe;