import React from "react";
import {
  useTheme,
  createTheme,
  ThemeProvider as MuiThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createLocalStorageStateHook } from "use-local-storage-state";
import * as colors from '@material-ui/core/colors';
import shadows from './shadows';
import { light, dark } from './palette';

// Create a local storage hook for dark mode preference
const useDarkModeStorage = createLocalStorageStateHook("isDarkMode");


const themeConfig = {
  light: {
    palette: {
      type: "light",
      primary: {
        main: colors.indigo["500"],
      },
      secondary: {
        main: colors.pink["500"],
      },
      background: {
        default: "#fff",
        paper: "#fff",
      },
    },
    shadows: shadows('light'),
    typography: {
      fontSize: 14,
      fontFamily: '"General Sans","Roboto", "Noto Sans SC","Helvetica", "Arial", sans-serif',
      button: { 
        fontWeight: 700,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            borderRadius: 5,
            paddingTop: 10,
            paddingBottom: 10,
          },
          containedSecondary: { color: 'white' },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
          input: {
            borderRadius: 5,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
    },
  },
  dark: {
    palette: {
      type: "dark",
      primary: {
        main: colors.indigo["500"],
      },
      secondary: {
        main: colors.pink["500"],
      },
      background: {
        default: colors.grey["900"],
        paper: colors.grey["800"],
      },
    },
    shadows: shadows('dark'),
  },
  common: {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1200,
        xl: 1920,
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "#root": {
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            "& > *": {
              flexShrink: 0,
            },
          },
        },
      },
    },
  },
};

const getTheme = (mode) => {
  const finalTheme = createTheme({
    ...themeConfig[mode],
    ...themeConfig.common,
    components: {
      ...themeConfig[mode].components,
      ...themeConfig.common.components,
    },
  });

  return responsiveFontSizes(finalTheme);
};

export const ThemeProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
  const [isDarkModeStored, setIsDarkModeStored] = useDarkModeStorage();
  const isDarkMode = isDarkModeStored === undefined ? prefersDarkMode : isDarkModeStored;
  const theme = getTheme(isDarkMode ? 'dark' : 'light');
  console.log("Current Theme:", theme); 
  
  theme.palette.toggle = () => setIsDarkModeStored(value => !value);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export function useDarkMode() {
  const theme = useTheme();
  const isDarkMode = theme.palette.type === 'dark';
  return { value: isDarkMode, toggle: theme.palette.toggle };
}