import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChatStripe from "./ChatStripe2";
import generateUniqueId from "../../util/generateuniqueid";
import { Grid, Typography, TextareaAutosize, Button } from '@material-ui/core';
import { useAuth } from "../../util/auth";
import { saveCMS } from "../../util/db";
import { TextEditor } from "../richtexteditor/TextEditor";
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    flexGrow: 1,
  },
  messageContainer: {
    overflowY: 'auto',
    maxHeight: '100%',
    paddingRight: theme.spacing(2),
  },
  textAreaContainer: {
    width: '100%',
    height: '100%',
  },
  editorContainer: {
    height: "800px",
    padding: "1rem",
    lineHeight: "1.6",
    fontSize: "1rem",
    textAlign: "left",
    backgroundColor: theme.palette.type === 'dark' ? '#424242' : '#ffffff',
    color: theme.palette.type === 'dark' ? 'white' : 'black',
    borderRadius: "8px",
    width: "100%",
    marginBottom: "2rem",
  },
  ai: {},
  copy: {
    display: ({ typing, load, error }) => (typing || load || error ? "none" : "block"),
    cursor: "pointer",
    "& img": { width: "22px" },
  },
  saveButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export async function fetchData(payload, auth, setMessages, setError, setLoading, setTyping, typingInterval) {
  let uniqueId;
  uniqueId = generateUniqueId();

  setMessages(oldMessages => [
    {
      isUser: false,
      value: '',
      error: false,
      uniqueId: uniqueId,
      loading: true
    },
    ...oldMessages
  ]);

  // fetching data from server 
  // fetch("http://localhost:5001/v2", {
  fetch("https://ctg-api.onrender.com/v2", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      prompt: payload,
      name: auth.user.displayName,
      uid: auth.user.uid,
      email: auth.user.email,
    }),
  })
  .then(async (response) => {
    if (response.ok) {
      setError(false);
      const data = await response.json();
      const parsedData = data.bot.trim();
      
      if (parsedData === "") {
        setMessages(oldMessages => [{
          isUser: false,
          value: "No results Found 😞\n<small>Change model</small>",
          error: false,
          uniqueId: uniqueId
        }, ...oldMessages]);
      } else {
        setTyping(true);
        setTimeout(() => {
          setMessages(oldMessages =>
            oldMessages.map(message => 
              message.uniqueId === uniqueId
                ? {
                    ...message,
                    value: parsedData,
                    loading: false
                  }
                : message
            )
          )
          setTyping(false);
        }, typingInterval);
      }
    } else {
      setError(true);
      setMessages(oldMessages =>
        oldMessages.map(message => 
          message.uniqueId === uniqueId
            ? {
                ...message,
                value: "An error occurred. \n<small>Hint: Try to <b>reduce your word count</b> or check your internet connection and then <b>try again</b><small>",
                error: true,
                loading: false
              }
            : message
        )
      );
    }
  })
  .catch((err) => {
    setError(true);
    setLoading(false);
    setMessages(oldMessages =>
      oldMessages.map(message => 
        message.uniqueId === uniqueId
          ? {
              ...message,
              value: "Sorry, server is down right now, try again later",
              error: true,
              loading: false
            }
          : message
      )
    );
    console.log(err)
    });
}

export async function fetchDataWithoutTyping(payload, auth) {
  return new Promise((resolve, reject) => {
    // fetching data from server 
    fetch("https://ctg-api.onrender.com/v2", {
    // fetch("http://localhost:5001/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prompt: payload,
        name: auth.user.displayName,
        uid: auth.user.uid,
        email: auth.user.email,
      }),
    })
    .then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        const parsedData = data.bot.trim();
        
        if (parsedData === "") {
          reject(new Error("No results found"));
        } else {
          resolve(parsedData);
        }
      } else {
        reject(new Error("An error occurred. Try to reduce your word count or check your internet connection."));
      }
    })
    .catch((err) => {
      console.error(err);
      reject(new Error("Sorry, server is down right now, try again later"));
    });
  });
}

export function OutputContainer({ typing, error, messages, id }) {
  const classes = useStyles({ typing, error });

  return (
    <div id={id}>
      {messages.map((message, index) => (
        <ChatStripe
          key={message.uniqueId || index}
          isAi={!message.isUser}
          value={message.value}
          uniqueId={message.uniqueId}
          showIcon={true} 
          loading={message.loading}
        />
      ))}

      <div className={classes.ai}></div>
      <div className={classes.copy}></div>
    </div>
  );
}

export async function fetchDataCombinedADDA(payload, auth, setMessages, setError, setLoading, setTyping, typingInterval) {
  return new Promise((resolve, reject) => {
    let adda_id;
    let trimmed_adda_id;

    // fetching data from server 
    fetch("https://ctg-api.onrender.com/v2", {
    // fetch("http://localhost:5001/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prompt: payload,
        name: auth.user.displayName,
        email: auth.user.email,
        uid: auth.user.uid,
      }),
    })
    .then(async (response) => {
      if (response.ok) {
        setError(false);
        const data = await response.json();
        const parsedData = data.bot.trim();
        adda_id = data.bot_adda;
        trimmed_adda_id = adda_id.substring(0, 4);

        let newMessage;
        if (parsedData === "") {
          newMessage = {
            isUser: false,
            value: "No results Found 😞\n<small>Change model</small>",
            error: false,
            uniqueId: trimmed_adda_id,
            adda_id: adda_id,
            loading: false
          };
        } else {
          setTyping(true);
          await new Promise(resolve => setTimeout(resolve, typingInterval));
          newMessage = {
            isUser: false,
            id: adda_id,
            value: parsedData,
            uniqueId: trimmed_adda_id,
            adda_id: adda_id,
            loading: false
          };
          setTyping(false);
        }
        setMessages(oldMessages => [newMessage, ...oldMessages.filter(message => message.uniqueId !== trimmed_adda_id)]);
        resolve(data);
      } else {
        setError(true);
        const newMessage = {
          isUser: false,
          value: "An error occurred. \n<small>Hint: Try to <b>reduce your word count</b> or check your internet connection and then <b>try again</b><small>",
          error: true,
          uniqueId: trimmed_adda_id,
          adda_id: adda_id,
          loading: false
        };
        setMessages(oldMessages => [newMessage, ...oldMessages.filter(message => message.uniqueId !== trimmed_adda_id)]);
        reject(new Error("Response not OK"));
      }
    })
    .catch((err) => {
      setError(true);
      setLoading(false);
      const newMessage = {
        isUser: false,
        value: "Sorry, server is down right now, try again later",
        error: true,
        uniqueId: trimmed_adda_id,
        loading: false
      };
      setMessages(oldMessages => [newMessage, ...oldMessages.filter(message => message.uniqueId !== trimmed_adda_id)]);
      console.log(err);
      reject(err);
    });
  });
}

export function OutputContainerCombinedADDA({ typing, error, messages, id, handleSubmit }) {
  const classes = useStyles({ typing, error });
  const auth = useAuth();
  const [textAreaContent, setTextAreaContent] = useState("");

  // Define placeholder messages
  const placeholderMessages = [
    { uniqueId: 'ad-1', isUser: false, value: '', adda_id: 'ad-1-cn-combined', loading: false },
    { uniqueId: 'ad-2', isUser: false, value: '', adda_id: 'ad-2-d-cn-combined', loading: false },
    { uniqueId: 'da-1', isUser: false, value: '', adda_id: 'da-1-cn-product', loading: false },
    { uniqueId: 'da-2', isUser: false, value: '', adda_id: 'da-2-cn-combined', loading: false },
  ];

  // Merge placeholder messages with actual messages
  const combinedMessages = placeholderMessages.map((placeholder) => {
    const actualMessage = messages.find(msg => msg.uniqueId === placeholder.uniqueId);
    return actualMessage || placeholder;
  });

  const handleAppend = (content) => {
    setTextAreaContent(prev => `${prev}<p>${content}</p>`);
  };

  const handleSave = async () => {
    try {
      await saveCMS(auth.user.uid, textAreaContent);
      alert('Content saved successfully!');
    } catch (error) {
      console.error('Failed to save content:', error);
      alert('Failed to save content.');
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} className={classes.messageContainer}>
        {combinedMessages.map((message, index) => (
          <ChatStripe
            key={message.uniqueId || index}
            isAi={!message.isUser}
            value={message.loading ? "" : message.value}
            uniqueId={message.uniqueId}
            adda_id={message.adda_id}
            showIcon={true} 
            loading={message.loading}
            combined={true}
            handleSubmit={handleSubmit}
            handleAppend={handleAppend}
          />
        ))}
      </Grid>
      <Grid item xs={6} className={classes.textAreaContainer}>
        <div className={classes.editorContainer}>
          <TextEditor
            script={textAreaContent}
            isEditMode={true}
            onContentChange={(newContent) => setTextAreaContent(newContent)}
          />
          <Button 
            onClick={handleSave} 
            variant="contained" 
            color="primary" 
            className={classes.saveButton}
          >
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export async function fetchDataCombinedPEPC(payload, auth, setMessages, setError, setLoading, setTyping, typingInterval) {
  return new Promise((resolve, reject) => {
    let pepc_id;
    let trimmed_pepc_id;

    fetch("https://ctg-api.onrender.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prompt: payload,
        name: auth.user.displayName,
        email: auth.user.email,
        uid: auth.user.uid,
      }),
    })
    .then(async (response) => {
      if (response.ok) {
        setError(false);
        const data = await response.json();
        
        if (!data.bot) {
          throw new Error("No bot response in data");
        }

        const parsedData = data.bot.trim();
        pepc_id = data.bot_adda || `pepc-${Date.now()}`;
        trimmed_pepc_id = pepc_id;

        setTyping(true);
        await new Promise(resolve => setTimeout(resolve, typingInterval));

        // Find and update the specific placeholder message
        setMessages(oldMessages => {
          return oldMessages.map(message => {
            if (message.uniqueId === trimmed_pepc_id || message.pepc_id === pepc_id) {
              return {
                isUser: false,
                id: pepc_id,
                value: parsedData,
                uniqueId: trimmed_pepc_id,
                pepc_id: pepc_id,
                loading: false,
                error: false
              };
            }
            return message;
          });
        });

        setTyping(false);
        setLoading(false);
        resolve(data);
      } else {
        throw new Error("Response not OK");
      }
    })
    .catch((err) => {
      console.error("Error in fetchDataCombinedPEPC:", err);
      setError(true);
      setLoading(false);
      
      // Update only the specific message that failed
      setMessages(oldMessages => {
        return oldMessages.map(message => {
          if (message.uniqueId === trimmed_pepc_id || message.pepc_id === pepc_id) {
            return {
              isUser: false,
              value: "Sorry, there was an error generating the response. Please try again.",
              error: true,
              uniqueId: trimmed_pepc_id || 'error',
              pepc_id: pepc_id,
              loading: false
            };
          }
          return message;
        });
      });
      
      reject(err);
    });
  });
}

export function OutputContainerCombinedPEPC({ typing, error, messages, id, handleSubmit }) {
  console.log("OutputContainerCombinedPEPC props:", { typing, error, messages });

  const classes = useStyles({ typing, error });
  const auth = useAuth();
  const [textAreaContent, setTextAreaContent] = useState("");

  const placeholderMessages = [
    { uniqueId: 'pepc-hook-cn', isUser: false, value: '', pepc_id: 'pepc-hook-cn', loading: true },
    { uniqueId: 'pepc-intro-cn', isUser: false, value: '', pepc_id: 'pepc-intro-cn', loading: true },
    { uniqueId: 'pepc-usp-result-cn', isUser: false, value: '', pepc_id: 'pepc-usp-result-cn', loading: true },
    { uniqueId: 'pepc-cta-cn', isUser: false, value: '', pepc_id: 'pepc-cta-cn', loading: true },
  ];

  // Merge placeholder messages with actual messages
  const combinedMessages = placeholderMessages.map((placeholder) => {
    const actualMessage = messages.find(msg => 
      msg.pepc_id === placeholder.pepc_id || 
      msg.uniqueId === placeholder.uniqueId
    );
    return actualMessage || placeholder;
  });

  const handleAppend = (content) => {
    setTextAreaContent(prev => `${prev}<p>${content}</p>`);
  };

  const handleSave = async () => {
    try {
      await saveCMS(auth.user.uid, textAreaContent);
      alert('Content saved successfully!');
    } catch (error) {
      console.error('Failed to save content:', error);
      alert('Failed to save content.');
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} className={classes.messageContainer}>
        {combinedMessages.map((message, index) => (
          <ChatStripe
            key={`${message.pepc_id}-${index}`}
            isAi={!message.isUser}
            value={message.value || ''}
            uniqueId={message.uniqueId}
            pepc_id={message.pepc_id}
            showIcon={true} 
            loading={message.loading}
            combined={true}
            handleSubmit={handleSubmit}
            handleAppend={handleAppend}
            error={message.error}
          />
        ))}
      </Grid>
      <Grid item xs={6} className={classes.textAreaContainer}>
        <div className={classes.editorContainer}>
          <TextEditor
            script={textAreaContent}
            isEditMode={true}
            onContentChange={(newContent) => setTextAreaContent(newContent)}
          />
          <Button 
            onClick={handleSave} 
            variant="contained" 
            color="primary" 
            className={classes.saveButton}
          >
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}