import React from "react";
import Meta from "../components/Meta";
import { requireProUser } from "../util/auth";
import TextToSpeechSection from "../components/elevenlabs/TextToSpeechSection";

function TextToSpeechPage(props) {
  return (
    <>
      <Meta title="Text to Speech" />
      <TextToSpeechSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Text to Speech"
        subtitle="文本转语音"
      />
    </>
  );
}

export default requireProUser(TextToSpeechPage);