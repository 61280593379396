import React, { useState, useEffect, useRef } from "react";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  writeBatch
} from "firebase/firestore";
import { firebaseApp } from "../../util/firebase";
import Box from "@material-ui/core/Box";
import {
  Typography,
  Button,
  Card,
  Grid,
  Divider,
  Avatar,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth";
import { Link } from "../../util/router";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import Modal from "@material-ui/core/Modal";
import CardContent from "@material-ui/core/CardContent";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "@orrisroot/react-html-parser";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTheme } from "@material-ui/core/styles";
import AdLibraryFilter from "./AdLibraryFilter";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Toaster, toast } from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    boxShadow: theme.shadows[4],
  },
  button: {
    color: "white",
    backgroundColor: "#3f51b5",
    "&:hover": {
      backgroundColor: "#303f9f",
    },
    margin: "10px",
  },
  disabledButton: {
    color: "rgba(0, 0, 0, 0.26)",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    margin: "10px",
  },
  typography: {
    fontWeight: "bold",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    justifyContent: "flex-start",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
    height: "80vh",
    overflow: "auto",
  },
  modalAdContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalContainerButtons: {
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
  },
  modalAdContent: {
    width: "80%",
  },
  squareCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    width: "95%",
    // height: "40rem",
    maxHeight: "95%",
    overflow: "auto",
  },
  cardBox: {
    display: "flex",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  cardActionArea: {
    display: "block",
    textAlign: "inherit",
    width: "100%",
  },
}));

const ScraperHistorySection = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const auth = useAuth();

  const [usageData, setUsageData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedDiv, setExpandedDiv] = useState(null);
  const [selectedCards, setSelectedCards] = useState([]);
  const [filterValue, setFilterValue] = useState(null);
  const [filterResults, setFilterResults] = useState("");
  const [mediaType, setMediaType] = useState("all");
  const [language, setLanguage] = useState("chinese");
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Pagination state 
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Calculate the index of the first and last items on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Slice the usageData array to get only the items for the current page
  const currentItems = usageData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(usageData.length / itemsPerPage);

  const toggleTruncateText = (index) => {
    setExpandedDiv(expandedDiv === index ? null : index);
  };

  const handleFilterChange = (selectedValue) => {
    // console.log(selectedValue)
    setFilterValue(selectedValue);
    setCurrentPage(1);
  };

  const handleFilterResultsChange = (selectedValue) => {
    // console.log(selectedValue)
    setFilterResults(selectedValue);
    // setCurrentPage(1);
  };

  const handleCardClick = (index) => {
    setSelectedCards((prevSelectedCards) => {
      if (prevSelectedCards.includes(index)) {
        // If the card is already selected, remove it from the selection
        return prevSelectedCards.filter((cardIndex) => cardIndex !== index);
      } else {
        // If the card is not selected, add it to the selection
        return [...prevSelectedCards, index];
      }
    });
  };

  const handleClearSelection = () => {
    setSelectedCards([]);
  };

  const uploadAndRecordMedia = async (mediaUrl, type, uploadID, adArchiveID) => {
    try {
      let formData = new FormData();
      formData.append('libraryID', uploadID);
      formData.append('mediaType', type)

      const workerUrl = `https://ctg-uploader-worker.callum-theadspert.workers.dev/?url=${encodeURIComponent(mediaUrl)}`;
      const uploadResponse = await fetch(workerUrl, {
        method: 'POST',
        body: formData
      });
  
      const uploadData = await uploadResponse.json();
      if (uploadResponse.ok) {
        return uploadData.cdnUrl;

        // // firebase section 
        // // save to user's document 
        // const docRef = doc(db, `users/${auth.user.uid}/savedAds/savedAdsCollection`);
        // const mediaField = type.startsWith('image') ? 'images' : 'videos';
        // const batch = writeBatch(db);

        // // check if document exists
        // const docSnap = await getDoc(docRef);

        // console.log(uploadData.cdnUrl)
        // const adData = {
        //   adArchiveID: adArchiveID,
        //   collationCount: data.collationCount,
        //   copywriting: data.copywriting,
        //   startDate: data.startDate,
        //   endDate: data.endDate,
        //   page_name: data.page_name,
        //   page_profile_picture: data.page_profile_picture,
        //   [mediaField]: [uploadData.cdnUrl],
        // };
        // if (docSnap.exists()) {
        //   const ads = docSnap.data().ads;
        //   const adIndex = ads.findIndex(ad => ad.adArchiveID === adArchiveID);
        
        //   if (adIndex !== -1) {
        //     // Ad exists, append new image URL to the mediaField array
        //     const newImageUrl = uploadData.cdnUrl;
        //     const fieldPath = `ads.${adIndex}.${mediaField}`;
        //     ads[adIndex][mediaField].push(newImageUrl);
        //     batch.update(docRef, { [fieldPath]: ads[adIndex][mediaField] });
        //   } else {
        //     // Ad does not exist, create new adData object
        //     const newAdData = {
        //       adArchiveID: adArchiveID,
        //       collationCount: data.collationCount,
        //       copywriting: data.copywriting,
        //       startDate: data.startDate,
        //       endDate: data.endDate,
        //       page_name: data.page_name,
        //       page_profile_picture: data.page_profile_picture,
        //       [mediaField]: [uploadData.cdnUrl],
        //     };
        //     ads.push(newAdData);
        //     batch.update(docRef, { ads });
        //   }
        
        //   // write the updated ads array back to Firestore
        //   await batch.commit();
        //   console.log("Batch committed successfully.");
        // } else {
        //   console.log("Document does not exist, initiating");
        //   await setDoc(docRef, {
        //     ads: [adData]  // init the array 
        //   });
        // }
      } else {
        console.error('Upload to Cloudflare R2 failed:', uploadData);
        return null;
      }
    } catch (error) {
      console.error('Error uploading media:', error);
      return null;
    }
  };

  const saveMedia = async () => {
    console.log("Starting saveMedia function");
    const db = getFirestore(firebaseApp);
    const batch = writeBatch(db);
    const docRef = doc(db, `users/${auth.user.uid}/savedAds/savedAdsCollection`);
    const docSnap = await getDoc(docRef);
    
    let ads = [];
    if (!docSnap.exists()) {
      console.log("Document does not exist, creating a new one.");
      await setDoc(docRef, { ads });
    } else {
      const docData = docSnap.data();
      // console.log("Existing document data:", JSON.stringify(docData, null, 2));
      ads = Array.isArray(docData.ads) ? docData.ads : [];
    }
  
    if (ads.length >= 100) {
      console.log("Maximum amount of saved ads reached.");
      toast.error("Maximum amount of saved ads reached.");
      return;
    } 

    await toast.promise(
      (async () => {
        for (let index of selectedCards) {
          let data = usageData[index];
          // console.log(`Processing ad ${data.adArchiveID}:`, data);
          const promises = [];
      
          if (Array.isArray(data.images)) {
            // console.log(`Found ${data.images.length} images for ad ${data.adArchiveID}`);
            data.images.forEach((image, i) => {
              const uploadID = `${data.adArchiveID}_${i}`;
              // console.log(`Attempting to upload image ${i} with ID ${uploadID}`);
              const promise = uploadAndRecordMedia(image, `image_${i}`, uploadID, data.adArchiveID)
                .then(cdnUrl => {
                  if (cdnUrl) {
                    const adIndex = ads.findIndex(ad => ad.adArchiveID === data.adArchiveID);
                    if (adIndex !== -1) {
                      ads[adIndex].images = ads[adIndex].images || [];
                      ads[adIndex].images.push(cdnUrl);
                    } else {
                      const newAdData = {
                        adArchiveID: data.adArchiveID,
                        collationCount: data.collationCount,
                        copywriting: data.copywriting,
                        startDate: data.startDate,
                        endDate: data.endDate,
                        page_name: data.page_name,
                        page_profile_picture: data.page_profile_picture,
                        images: [cdnUrl],
                      };
                      ads.push(newAdData);
                    }
                  }
                })
                .catch(error => {
                  console.error(`Failed to upload image ${i} for ad ${data.adArchiveID}:`, error);
                  toast.error(`Failed to save image ${i + 1}`);
                });
              promises.push(promise);
            });
          }

          if (Array.isArray(data.videos)) {
            const firstVideo = data.videos[0];
            const uploadID = `${data.adArchiveID}`;
            const promise = uploadAndRecordMedia(firstVideo, `video`, uploadID, data.adArchiveID)
              .then(cdnUrl => {
                if (cdnUrl) {
                  const adIndex = ads.findIndex(ad => ad.adArchiveID === data.adArchiveID);
                  if (adIndex !== -1) {
                    ads[adIndex].videos = ads[adIndex].videos || [];
                    ads[adIndex].videos.push(cdnUrl);
                  } else {
                    const newAdData = {
                      adArchiveID: data.adArchiveID,
                      collationCount: data.collationCount,
                      copywriting: data.copywriting,
                      startDate: data.startDate,
                      endDate: data.endDate,
                      page_name: data.page_name,
                      page_profile_picture: data.page_profile_picture,
                      videos: [cdnUrl],
                    };
                    ads.push(newAdData);
                  }
                }
              })
              .catch(error => {
                console.error(`Failed to upload video for ad ${data.adArchiveID}:`, error);
                toast.error(`Failed to save video`);
              });
            promises.push(promise);
          }
          
          await Promise.all(promises);
        }
      })(),
      {
        loading: 'Saving media...',
        success: 'All media saved successfully!',
        error: 'Some media failed to save.'
      }
    );
  
    console.log("Final ads array before commit:", ads);
    batch.set(docRef, { ads });
    await batch.commit();
    console.log("Batch committed successfully.");
  };

  const handleDownloadMedia = async () => {
    await toast.promise(
      (async () => {
        for (let index of selectedCards) {
          let data = usageData[index];
          const imagePromises = Array.isArray(data.images) ? data.images.map((image, idx) => 
            downloadFile("image", data.adArchiveID, image)
              .then(() => toast.success(`Image ${idx + 1} downloaded successfully!`))
              .catch(error => {
                console.error(`Error downloading image ${idx + 1} for ad ${data.adArchiveID}:`, error);
                toast.error(`Error downloading image ${idx + 1}`);
              })
          ) : [];
  
          const videoPromises = Array.isArray(data.videos) ? data.videos.map((video, idx) => 
            downloadFile("video", data.adArchiveID, video)
              .then(() => toast.success(`Video ${idx + 1} downloaded successfully!`))
              .catch(error => {
                console.error(`Error downloading video ${idx + 1} for ad ${data.adArchiveID}:`, error);
                toast.error(`Error downloading video ${idx + 1}`);
              })
          ) : [];
  
          const allPromises = [...imagePromises, ...videoPromises];
          if (allPromises.length === 0) {
            toast('No media to download.', { icon: 'info' });
          }
          await Promise.all(allPromises);
        }
      })(),
      {
        loading: 'Downloading media...',
        success: 'All media downloaded successfully!',
        error: 'Some media failed to download.'
      }
    );
  };

  // keeping just in case for the time being
  // const handleDownloadMedia = async () => {
  //   for (let index of selectedCards) {
  //     let data = usageData[index];
  //     if (Array.isArray(data.images)) {
  //       const downloadPromises = data.images.map(async (image, index) => {
  //         try {
  //           await downloadFile("image", data.adArchiveID, image);
  //         } catch (error) {
  //           console.error(`Error downloading image ${index} for ad ${data.adArchiveID}:`, error);
  //         }
  //       });
  //       await Promise.all(downloadPromises);
  //     } else {
  //       console.log("No images to download.");
  //     }
    
  //     if (Array.isArray(data.videos) && data.videos.length > 0) {
  //       const downloadPromises = data.videos.map(async (video, index) => {
  //         try {
  //           await downloadFile("video", data.adArchiveID, video);
  //         } catch (error) {
  //           console.error(`Error downloading video ${index} for ad ${data.adArchiveID}:`, error);
  //         }
  //       });
  //       await Promise.all(downloadPromises);
  //     } else {
  //       console.log("No videos to download.");
  //     }
  //   }
  // };

  async function downloadFile(mimeType, filename, externalUrl) {
    const workerUrl = `https://ctg-uploader-worker.callum-theadspert.workers.dev/download?url=${encodeURIComponent(externalUrl)}`;
    const formData = new FormData();
    formData.append('externalUrl', externalUrl);
    formData.append('mediaType', mimeType)
    const response = await fetch(workerUrl, {
      method: 'POST', 
      body: formData, 
    });
    if (!response.ok) {
      console.error('Failed to download file from Cloudflare Worker');
      return;
    }
    const blob = await response.blob();
    const urlObject = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = urlObject;

    let extension = '';
    if (mimeType.startsWith('image/')) {
      extension = 'jpg';
    } else if (mimeType.startsWith('video/')) {
      extension = 'mp4';
    }
  
    a.download = `${filename}.${extension}`;
  
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  // Modal handlers
  const handleClose = () => {
    setOpen(false);
  };

  const handleModalNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handleModalPrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const handleMediaChange = (event) => {
    setMediaType(event.target.value);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  }
  
  const handleSearchClick = async () => {
    setIsLoading(true);  
    try {
      await toast.promise(
        fetchData(auth),  
        {
          loading: 'Analyzing data...',
          success: 'Data returned successfully!',
          error: 'Failed to fetch data.'
        }
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    } 
  };

  async function fetchData(auth, setLoading) {
    console.log('fetching data')
  
    // fetching data from server 
    // return fetch("http://localhost:5001/scrape", {
    return fetch("https://ctg-api.onrender.com/scrape", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        searchQuery: searchQuery,
        language: language,
        mediaType: mediaType,
        name: auth.user.displayName,
        uid: auth.user.uid,
      }),
    })
    .then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        console.log(response)
        setUsageData(data.response);
        setIsLoading(false);
        return data;
      } else {
        throw new Error('Failed to fetch data'); 
      }
    })
    .catch((err) => {
        console.error('Error fetching data:', err);
        setIsLoading(false)
        throw err;
      });
  }

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Toaster position="bottom-right" reverseOrder={false} />
        <AdLibraryFilter
          selectedCards={selectedCards}
          saveMedia={saveMedia}
          onFilterChange={handleFilterChange}
          onFilterResultsChange={handleFilterResultsChange}
          onClearSelection={handleClearSelection}
          mediaType={mediaType}
          language={language}
          searchQuery={searchQuery}
          onMediaChange={handleMediaChange}
          onLanguageChange={handleLanguageChange}
          onSearchQueryChange={handleSearchQueryChange}
          onSearchClick={handleSearchClick}
          isLoading={isLoading}
          onDownloadMedia={handleDownloadMedia}
          clearData={() => setUsageData([])}
        />

        {/* keeping for future debugging purposes */}
        {/* <Button
          onClick={() => {
            console.log("total items", usageData);
            // console.log("current items", currentItems);
          }}
        >
          test data
        </Button> */}
        {usageData.length > itemsPerPage && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCurrentPage((old) => Math.max(old - 1, 1))}
              disabled={currentPage === 1}
            >
              Prev
            </Button>

            <Typography variant="subtitle2">
              Page {currentPage} of {totalPages}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                setCurrentPage((old) => Math.min(old + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </Box>
        )}
        <Grid container>
          {currentItems.map((data, index) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Card
                    className={classes.squareCard}
                    key={index}
                    // onClick={() => handleCardClick(index)}
                    style={{
                      // backgroundColor: selectedCards.includes(index)
                      //   ? "#f0f0f0"
                      //   : "white",
                      border: selectedCards.includes(index)
                        ? "1px solid #087F8C"
                        : "none",
                      boxShadow: selectedCards.includes(index)
                        ? "0px 0px 10px 2px rgba(63,81,181,0.5)"
                        : "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
                      transition: "all 0.1s ease",
                    }}
                  >
                    <Box className={classes.cardActionArea}>
                      <CardContent style={{ position: "relative" }}>
                        <CheckCircleIcon
                          fontSize="large"
                          style={{
                            color: selectedCards.includes(index)
                              ? "#3f51b5"
                              : "#ccc",
                            position: "absolute",
                            top: 10,
                            right: 10,
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleCardClick(index);
                          }}
                        />

                        <Box mb={1}>
                          <Typography variant="body2">
                            Library ID: {data.adArchiveID}
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography variant="body2">
                            Started running on <strong>{new Date(data.startDate * 1000).toLocaleDateString()}</strong>
                          </Typography>
                        </Box>
                        
                        {/* <Box mb={1}>
                          <Typography variant="body2">
                            <CheckCircleIcon fontSize="inherit" />
                            Active
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography variant="body2">
                            Started running on 1 Nov 2023
                          </Typography>
                        </Box> */}

                        <Box mb={1}>
                          <Typography variant="body2">
                            <strong>{data.collationCount} ads</strong> using
                            this creative and text
                          </Typography>
                        </Box>
                        <Divider
                          style={{ marginTop: "1rem", marginBottom: "1rem" }}
                        />

                        <Box display="flex" alignItems="center" mb={3}>
                          <Avatar
                            style={{ width: "2rem", height: "2rem" }}
                            src={data.page_profile_picture}
                          />
                          <Box ml={1.5}>
                            <Typography
                              style={{ fontWeight: "900" }}
                              variant="body2"
                              component="h4"
                            >
                              {data.page_name}
                            </Typography>
                            <Typography
                              style={{ fontWeight: "700" }}
                              variant="caption"
                              color="textSecondary"
                            >
                              Sponsored
                            </Typography>
                          </Box>
                        </Box>

                        <Box mb={3} onClick={() => toggleTruncateText(index)}>
                          <div>
                            {data &&
                              data.copywriting &&
                              data.copywriting.length > 0 &&
                              ReactHtmlParser(
                                expandedDiv === index
                                  ? data.copywriting[0]
                                  : `${data.copywriting[0].substring(
                                      0,
                                      100
                                    )} ...`
                              )}
                          </div>
                        </Box>
                        {data && data.images && data.images.length > 0 && (
                          <Box
                            bgcolor={theme.palette.grey[50]}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                            height="100%"
                          >
                            <Carousel
                              showThumbs={false}
                              showStatus={false}
                              dynamicHeight={true}
                              width="200px"
                              infiniteLoop={true}
                              autoPlay={true}
                              stopOnHover={true}
                              showIndicators={false}
                            >
                              {data.images.map((image, index) => (
                                <div key={index}>
                                  <img
                                    src={image}
                                    alt={`Winning ads image ${index}`}
                                  />
                                </div>
                              ))}
                            </Carousel>
                          </Box>
                        )}
                        {/* {data && data.images && data.images[0] && (
                          <Box
                            bgcolor={theme.palette.grey[50]}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                            height="100%"
                          >
                            <img
                              src={data.images[0]}
                              alt="Winning ads image"
                              style={{ width: "200px", height: "200px" }}
                            />
                          </Box>
                        )} */}

                        {data && data.videos && data.videos[0] && (
                          <Box
                            bgcolor={theme.palette.grey[50]}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                            height="100%"
                          >
                            <video
                              src={data.videos[0]}
                              alt="Winning ads video"
                              style={{ width: "200px", height: "200px" }}
                              controls
                            />
                          </Box>
                        )}
                      </CardContent>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          <div className={classes.modalContent}>
            <h2>
              {selectedData &&
                new Date(
                  selectedData.date.seconds * 1000 +
                    selectedData.date.nanoseconds / 1000000
                ).toLocaleString()}
            </h2>

            {selectedData && selectedData.length > 0 && (
              <div className={classes.modalAdContainer}>
                <div className={classes.modalContainerButtons}>
                  <Button
                    onClick={handleModalPrev}
                    disabled={currentIndex === 0}
                  >
                    Prev
                  </Button>
                  <Button
                    onClick={handleModalNext}
                    disabled={currentIndex === selectedData.length - 1}
                  >
                    Next
                  </Button>
                </div>
                <Card className={classes.modalAdContent}>
                  <CardContent>
                    <h3>
                      Ad Archive ID: {selectedData[currentIndex].adArchiveID}
                    </h3>
                    {selectedData[currentIndex].images &&
                      selectedData[currentIndex].images.map((image, i) => (
                        <img
                          key={i}
                          src={image}
                          style={{ maxWidth: "300px" }}
                        />
                      ))}
                    {selectedData[currentIndex].videos &&
                      selectedData[currentIndex].videos.map((video, i) => (
                        <video key={i} width="300" height="300" controls>
                          <source src={video} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ))}
                    {selectedData[currentIndex].copywriting.map((copy, i) => (
                      <div key={i}>{ReactHtmlParser(copy)}</div>
                    ))}
                  </CardContent>
                </Card>
              </div>
            )}
          </div>
        </Modal>
      </Container>
    </Section>
  );
};

export default ScraperHistorySection;
