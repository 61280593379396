export function IdIdentifierGeneral({ id }) {
  switch(id) {
    // ADDA
    case 'ad-1-cn':
    case 'ad-1-en':
    case 'ad-1-malay':
    case 'ad-1-cn-combined':
    case 'ad-1-en-combined':
    case 'ad-1-malay-combined':
      return 'Attention 文案';
    case 'ad-2-s-cn':
    case 'ad-2-s-en':
    case 'ad-2-s-malay':
    case 'ad-2-d-cn':
    case 'ad-2-d-en':
    case 'ad-2-d-malay':
    case 'ad-2-f-cn':
    case 'ad-2-f-en':
    case 'ad-2-f-malay':
    case 'ad-2-s-cn-combined':
    case 'ad-2-s-en-combined':
    case 'ad-2-s-malay-combined':
    case 'ad-2-d-cn-combined':
    case 'ad-2-d-en-combined':
    case 'ad-2-d-malay-combined':
    case 'ad-2-f-cn-combined':
    case 'ad-2-f-en-combined':
    case 'ad-2-f-malay-combined':
      return 'Demand 文案';
    case 'da-1-cn-product':
    case 'da-1-en-product':
    case 'da-1-malay-product':
    case 'da-1-cn-service':
    case 'da-1-en-service':
    case 'da-1-malay-service':
      return 'Description 文案';
    case 'da-2-cn':
    case 'da-2-en':
    case 'da-2-malay':
    case 'da-2-cn-combined':
    case 'da-2-en-combined':
    case 'da-2-malay-combined':
      return 'Action 文案';

    // Simplifier
    case 'simpli-1-cn':
    case 'simpli-1-en':
    case 'simpli-1-malay':
      return 'Simplifier 文案->白话';

    // USP
    case 'usp-1-cn':
    case 'usp-1-en':
    case 'usp-1-malay':
      return 'USP 独特卖点 文案';
    
    // SWOT
    case 'swot-s-cn':
    case 'swot-s-en':
    case 'swot-s-malay':
    case 'swot-w-cn':
    case 'swot-w-en':
    case 'swot-w-malay':
    case 'swot-o-cn':
    case 'swot-o-en':
    case 'swot-o-malay':
    case 'swot-t-cn':
    case 'swot-t-en':
    case 'swot-t-malay':
    case 'swot-swot-cn':
    case 'swot-swot-en':
    case 'swot-swot-malay':
    case 'swot-audience-cn':
    case 'swot-audience-en':
    case 'swot-audience-malay':
      return 'SWOT 文案';

    // Reply SOP ACCA
    case 'acca-aware-cn':
    case 'acca-aware-en':
    case 'acca-aware-malay':
    case 'acca-cons-cn':
    case 'acca-cons-en':
    case 'acca-cons-malay':
    case 'acca-cred-1-cn':
    case 'acca-cred-1-en':
    case 'acca-cred-1-malay':
    case 'acca-cred-2-cn':
    case 'acca-cred-2-en':
    case 'acca-cred-2-malay':
    case 'acca-cred-3-cn':
    case 'acca-cred-3-en':
    case 'acca-cred-3-malay':
    case 'acca-action-cn':
    case 'acca-action-en':
    case 'acca-action-malay':
      return 'Reply SOP';

    // Content Angle Generator
    case 'ca-generator-scene-cn':
    case 'ca-generator-scene-en':
    case 'ca-generator-scene-malay':
      return 'Content Angle';

    // Drama
    case 'drama-1':
    case 'drama-1-youmo':
    case 'drama-1-fanzhuan':
    case 'drama-1-ganren':
      return '剧本';
    case 'pepc-p-cn':
    case 'pepc-p-en':
    case 'pepc-p-malay':
    case 'pepc-p-2':
    case 'pepc-p-3':
      return '剧本营销';
    case 'pepc-hook-cn':
    case 'pepc-hook-en':
    case 'pepc-hook-malay':
      return 'PEPC Hook';
    case 'pepc-intro-cn':
    case 'pepc-intro-en':
    case 'pepc-intro-malay':
      return 'PEPC Introduction';
    case 'pepc-usp-result-cn':
    case 'pepc-usp-result-en':
    case 'pepc-usp-result-malay':
      return 'PEPC USP & Result';
    case 'pepc-cta-cn':
    case 'pepc-cta-en':
    case 'pepc-cta-malay':
      return 'PEPC Call to Action';

    // Reply Confidence
    case 'cf-endres-cn':
    case 'cf-gongsi-cn':
    case 'cf-jiangxiang-cn':
    case 'cf-ingredients-cn':
    case 'cf-quanwei-cn':
    case 'cf-theory-cn':
    case 'cf-usp-cn':
    case 'cf-xinxin-cn':
    case 'cf-zhuanye-cn':
    case 'cf-jianzheng-cn':
    case 'cf-endres-en':
    case 'cf-gongsi-en':
    case 'cf-jiangxiang-en':
    case 'cf-ingredients-en':
    case 'cf-quanwei-en':
    case 'cf-theory-en':
    case 'cf-usp-en':
    case 'cf-xinxin-en':
    case 'cf-zhuanye-en':
    case 'cf-jianzheng-en':
    case 'cf-endres-malay':
    case 'cf-gongsi-malay':
    case 'cf-jiangxiang-malay':
    case 'cf-ingredients-malay':
    case 'cf-quanwei-malay':
    case 'cf-theory-malay':
    case 'cf-usp-malay':
    case 'cf-xinxin-malay':
    case 'cf-zhuanye-malay':
    case 'cf-jianzheng-malay':
      return '信心回复';
    
    default:
      return 'Previous Results';
  }
}

export function IdIdentifierSpecific({ id }) {
  switch(id){
    // ADDA
    case 'ad-1-cn':
      return 'Attention (Chinese)';
    case 'ad-2-s-cn':
      return 'Demand Storytelling (Chinese)';
    case 'ad-2-d-cn':
      return 'Demand Scenario (Chinese)';
    case 'ad-2-f-cn':
      return 'Demand Feeling (Chinese)';
    case 'ad-1-en':
      return 'Attention (English)';
    case 'ad-2-s-en':
      return 'Demand Storytelling (English)';
    case 'ad-2-d-en':
      return 'Demand Scenario (English)';
    case 'ad-2-f-en':
      return 'Demand Feeling (English)';
    case 'ad-1-malay':
      return 'Attention (Malay)';
    case 'ad-2-s-malay':
      return 'Demand Storytelling (Malay)';
    case 'ad-2-d-malay':
      return 'Demand Scenario (Malay)';
    case 'ad-2-f-malay':
      return 'Demand Feeling (Malay)';
    case 'da-1-cn-product':
      return 'Product Description (Chinese)';
    case 'da-1-cn-service':
      return 'Service Description (Chinese)';
    case'da-2-cn':
      return 'Action (Chinese)';
    case 'da-1-en-product':
      return 'Product Description (English)';
    case 'da-1-en-service':
      return 'Service Description (English)';
    case'da-2-en':
      return 'Action (English)';
    case 'da-1-malay-product':
      return 'Product Description (Malay)';
    case 'da-1-malay-service':
      return 'Service Description (Malay)';
    case'da-2-malay':
     return 'Action (Malay)';
    
    // Simplifier
    case 'simpli-1-cn':
      return 'Simplifier (Chinese)';
    case 'simpli-1-en':
      return 'Simplifier (English)';
    case 'simpli-1-malay':
      return 'Simplifier (Malay)';

    // USP
    case 'usp-1-cn':
      return 'USP (Chinese)';
    case 'usp-1-en':
      return 'USP (English)';
    case 'usp-1-malay':
      return 'USP (Malay)';
    
    // SWOT
    case 'swot-s-cn':
      return 'SWOT 优势 (Chinese)';
    case 'swot-w-cn':
      return 'SWOT 劣势 (Chinese)';
    case 'swot-o-cn':
      return 'SWOT 机会 (Chinese)';
    case 'swot-t-cn':
      return 'SWOT 威胁 (Chinese)';
    case 'swot-swot-cn':
      return 'SWOT 强弱危机 (Chinese)';
    case 'swot-audience-cn':
      return 'SWOT 目标人群 (Chinese)';
    case 'swot-s-en':
      return 'SWOT Strength (English)';
    case 'swot-w-en':
      return 'SWOT Weakness (English)';
    case 'swot-o-en':
      return 'SWOT Opportunity (English)';
    case 'swot-t-en':
      return 'SWOT Threat (English)';
    case 'swot-swot-en':
      return 'SWOT Analysis (English)';
    case 'swot-audience-en':
      return 'SWOT Target Audience (English)';
    case 'swot-s-malay':
      return 'SWOT Strength (Malay)';
    case 'swot-w-malay':
      return 'SWOT Weakness (Malay)';
    case 'swot-o-malay':
      return 'SWOT Opportunity (Malay)';
    case 'swot-t-malay':
      return 'SWOT Threat (Malay)';
    case 'swot-swot-malay':
      return 'SWOT Analysis (Malay)';
    case 'swot-audience-malay':
      return 'SWOT Target Audience (Malay)';
    
    // Reply SOP ACCA
    case 'acca-aware-cn':
      return 'Reply SOP Awareness (Chinese)';
    case 'acca-cons-cn':
      return 'Reply SOP Consideration (Chinese)';
    case 'acca-cred-1-cn':
      return 'Reply SOP Credibility 1 (Chinese)';
    case 'acca-cred-2-cn':
      return 'Reply SOP Credibility 2 (Chinese)';
    case 'acca-cred-3-cn':
      return 'Reply SOP Credibility 3 (Chinese)';
    case 'acca-action-cn':
      return 'Reply SOP Action (Chinese)';
    case 'acca-aware-en':
      return 'Reply SOP Awareness (English)';
    case 'acca-cons-en':
      return 'Reply SOP Consideration (English)';
    case 'acca-cred-1-en':
      return 'Reply SOP Credibility 1 (English)';
    case 'acca-cred-2-en':
      return 'Reply SOP Credibility 2 (English)';
    case 'acca-cred-3-en':
      return 'Reply SOP Credibility 3 (English)';
    case 'acca-action-en':
      return 'Reply SOP Action (English)';
    case 'acca-aware-malay':
      return 'Reply SOP Awareness (Malay)';
    case 'acca-cons-malay':
      return 'Reply SOP Consideration (Malay)';
    case 'acca-cred-1-malay':
      return 'Reply SOP Credibility 1 (Malay)';
    case 'acca-cred-2-malay':
      return 'Reply SOP Credibility 2 (Malay)';
    case 'acca-cred-3-malay':
      return 'Reply SOP Credibility 3 (Malay)';
    case 'acca-action-malay':
      return 'Reply SOP Action (Malay)';
    
    // Content Angle Generator
    case 'ca-generator-scene-cn':
      return 'Content Angle Generator Scene (Chinese)';
    case 'ca-generator-scene-en':
      return 'Content Angle Generator Scene (English)';
    case 'ca-generator-scene-malay':
      return 'Content Angle Generator Scene (Malay)';
    
    // Drama
    case 'drama-1':
      return '剧本(通用)';
    case 'drama-1-youmo':
      return '剧本(幽默)';
    case 'drama-1-fanzhuan':
      return '剧本(反转)';
    case 'drama-1-ganren':
      return '剧本(感人)';
    case 'pepc-p':
      return '剧本营销';
    case 'pepc-p-2':
      return '剧本营销(针对痛点)';
    case 'pepc-p-3':
      return '剧本营销(颠覆认知)';

    // Reply Confidence
    case 'cf-endres-cn':
      return '信心回复(End Result)(Chinese)';
    case 'cf-gongsi-cn':
      return '信心回复(公司背景)(Chinese)';
    case 'cf-jiangxiang-cn':
      return '信心回复(获得奖赏)(Chinese)';
    case 'cf-ingredients-cn':
      return '信心回复(成分/技术)(Chinese)';
    case 'cf-quanwei-cn':
      return '信心回复(权威认证)(Chinese)';
    case 'cf-theory-cn':
      return '信心回复(原理解释)(Chinese)';
    case 'cf-usp-cn':
      return '信心回复(独特卖点)(Chinese)';
    case 'cf-xinxin-cn':
      return '信心回复(信心回复)(Chinese)';
    case 'cf-zhuanye-cn':
      return '信心回复(专业背景)(Chinese)';
    case 'cf-jianzheng-cn':
      return '信心回复(客户见证)(Chinese)';
    case 'cf-endres-en':
      return '信心回复(End Result)(English)';
    case 'cf-gongsi-en':
      return '信心回复(公司背景)(English)';
    case 'cf-jiangxiang-en':
      return '信心回复(获得奖赏)(English)';
    case 'cf-ingredients-en':
      return '信心回复(成分/技术)(English)';
    case 'cf-quanwei-en':
      return '信心回复(权威认证)(English)';
    case 'cf-theory-en':
      return '信心回复(原理解释)(English)';
    case 'cf-usp-en':
      return '信心回复(独特卖点)(English)';
    case 'cf-xinxin-en':
      return '信心回复(信心回复)(English)';
    case 'cf-zhuanye-en':
      return '信心回复(专业背景)(English)';
    case 'cf-jianzheng-en':
      return '信心回复(客户见证)(English)';
    case 'cf-endres-malay':
      return '信心回复(End Result)(Malay)';
    case 'cf-gongsi-malay':
      return '信心回复(公司背景)(Malay)';
    case 'cf-jiangxiang-malay':
      return '信心回复(获得奖赏)(Malay)';
    case 'cf-ingredients-malay':
      return '信心回复(成分/技术)(Malay)';
    case 'cf-quanwei-malay':
      return '信心回复(权威认证)(Malay)';
    case 'cf-theory-malay':
      return '信心回复(原理解释)(Malay)';
    case 'cf-usp-malay':
      return '信心回复(独特卖点)(Malay)';
    case 'cf-xinxin-malay':
      return '信心回复(信心回复)(Malay)';
    case 'cf-zhuanye-malay':
      return '信心回复(专业背景)(Malay)';
    case 'cf-jianzheng-malay':
      return '信心回复(客户见证)(Malay)';
    
    default:
      return '';
  }
}