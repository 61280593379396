import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import ScriptSection from "../../components/drama/ScriptSection";

function ScriptPage(props) {
  return (
    <>
      <Meta title="Video Script" />
      <ScriptSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="剧本脚本"
        subtitle="Script"
      />
    </>
  );
}

export default requireProUser(ScriptPage);
