import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTheme } from "@material-ui/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function FaqSection(props) {
  const theme = useTheme();

  const items = [
    {
      question: "What is AI generated content?",
      answer:
        "AI generated content is a service that utilizes artificial intelligence technology to automatically write advertising and marketing copy. It aims to save time, increase efficiency, and optimize your marketing strategy.",
    },
    {
      question: "What are the advantages of using your CTG AI service?",
      answer:
        "By using our AI copywriting service, you can obtain fast, high-quality, and personalized copy that meets your advertising and marketing needs. Additionally, AI copywriting can reduce your workload, enhance efficiency, and improve your marketing strategy.",
    },
    {
      question: "AI赋能量系统能用来做什么？",
      answer: "我们的AI赋能量系统里从建立项目USP，到广告分析，到制图，到建立回复攻略和成交文案等等，都能帮到你。基本上从无至有，都用得上我们独家研发的AI赋能量系统。"
    },
    {
      question: "怎么用AI赋能量系统最有效？",
      answer:
        "By using our AI copywriting service, you can obtain fast, high-quality, and personalized copy that meets your advertising and marketing needs. Additionally, AI copywriting can reduce your workload, enhance efficiency, and improve your marketing strategy.",
    },
    {
      question: "我刚要开始项目，适合用你们的AI赋能量系统吗？",
      answer:
        "By using our AI copywriting service, you can obtain fast, high-quality, and personalized copy that meets your advertising and marketing needs. Additionally, AI copywriting can reduce your workload, enhance efficiency, and improve your marketing strategy.",
    },
    {
      question: "还没有项目可以开始，能用你们的系统吗？",
      answer:
        "可以的，我们的系统里可以帮你建立Potential项目的强弱危机，定位目标群众，设立有效的USP甚至帮你分析市场上跑着的广告，协助你研发项目的过程",
    },
    {
      question: "你们的AI赋能量系统会持续更新吗？",
      answer:
        "我们的系统一定会随着市场研发和AI技术的发展去做持续更新和优化。从刚推出到现在，我们的系统已经经过了很多次的迭代，而且会一直迭代下去",
    },

  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <Box marginBottom={4} textAlign="center">
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 800 }}>
            <Typography color="primary" variant="inherit" component="span">
              FAQ
            </Typography>
          </Typography>
          <Typography component="p" color="text.secondary">
            Your questions, our answers
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: theme.spacing(4),
          }}
        >
          {items.map((item, index) => (
            <Accordion
              sx={{
                width: "100%",
                boxShadow: "none",
                "&:before": {
                  display: "none",
                },
                "&:not(:last-child)": {
                  borderBottom: `1px solid ${theme.palette.divider}`,
                },
                "&.Mui-expanded": {
                  margin: "0 !important",
                },
              }}
              key={index}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`faq-panel-${index}`}
                sx={{
                  minHeight: 78,
                  '& .MuiAccordionSummary-content': {
                    margin: "0 !important",
                  },
                }}
              >
                <Typography variant="h6">{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails id={`faq-panel-${index}`}>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Section>
  );
}

export default FaqSection;