import React, { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Button, Dialog, Divider, Slide } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import '../../style/container.css'
import { Toaster, toast } from "react-hot-toast";
import { useAuth } from "../../util/auth";
import { chatContext } from "../../context/ChatContext"
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import CombinedADDAItems from "./CombinedADDAItems";
import { IdIdentifierGeneral } from "./../functionalComponent/IdIdentifier";
import { fetchData, fetchDataCombinedADDA, OutputContainer, OutputContainerCombinedADDA } from "./../functionalComponent/fetchData";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "885px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
  },
  ay: {
  },
  copy: {
    display: ({ typing, load, error }) => typing || load || error ? "none" : "block",
    cursor: "pointer",
    "& img": { width: "22px" },
  },
  rotatedButton: {
    // transform: "rotate(-90deg)",
    padding: theme.spacing(2),
    fontSize: '1rem',
  },
  dialog: {
    // alignItems: "flex-end !important",
    maxHeight: "95vh",
  }
}));

function CombinedADDASection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState(false);
  const [openDialog, setOpenDialog] = useState(true);
  
  const {
    setIsChatOpen,
    setClearChat,
    typingInterval,
    selfDefinedValue,
  } = useContext(chatContext);

  const [industryAD, setIndustryAD] = useState("general");
  const [industryDA, setIndustryDA] = useState("product");
  const [tone, setTone] = useState("general");
  const [productName, setProductName] = useState("");
  const [productUSP, setProductUSP] = useState("");
  const [productContent, setProductContent] = useState("");
  const [productIngredient, setProductIngredient] = useState("");
  const [productCert, setProductCert] = useState("");
  const [legacyMode, setLegacyMode] = useState(false);

  // State to manage the list of messages
  const [messages, setMessages] = useState([]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleLegacyModeChange = (newLegacyMode) => {
    setLegacyMode(newLegacyMode);
  };

  const handleProductNameChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductName(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleProductUSPChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductUSP(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleProductContentChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductContent(event.target && event.target.value ? event.target.value : event);
    }
  };
  
  const handleToneChange = (event) => {
    setTone(event.target.value);
  };

  const handleProductIngredientChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductIngredient(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleProductCertChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductCert(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleIndustryDAChange = (event) => {
    setIndustryDA(event.target.value);
  };

  async function handleSubmit(ids, e, lastInput = false, speechRes = false, credit = 0.4) {
    e && e.preventDefault();
    e && e.stopPropagation();
    handleClose();
    // bot's chatStripe
    setLoading(true);
    setIsChatOpen(true); 
    setClearChat(false);
  
    // Check if ids is an array or a single id
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    // Iterate over each id and call fetchData
    for (let id of ids) {
      let query;
  
      if (id.includes("ad-")) {
        query = `Product info: \n
        The product name and type is: ${productName} \n
        Unique Selling Point is: ${productUSP} \n` 
        + ((tone !== "general") ? `Generate the copy using a ${tone} tone, ` : ``) + ((productContent.length > 2)  ? `and this style of content: ${productContent}. ` : ``);
      } else if (id.includes("da-")) {
        if (industryDA === "product") {
          query = `Product info: \n
          The product name or type is: ${productName} \n
          Unique Selling Point is: ${productUSP} \n`;
  
          if (productIngredient.length > 2) {
            query = query + `The unique ingredient or technology is: ${productIngredient} \n`;
          }
  
          if (productCert.length > 2) {
            query = query + `Has certification of: ${productCert} \n`;
          }
        } else {
          query = `Product info: \n
          The product name or type is: ${productName} \n
          Unique Selling Point is: ${productUSP} \n`;
  
          if (productIngredient.length > 2) {
            query = query + `The unique ingredient or technology is: ${productIngredient} \n`;
          }
  
          if (productCert.length > 2) {
            query = query + `Confidence boosting recognitions are: ${productCert} \n`;
          }
        }
  
        if (id === "da-1-cn" || id === "da-1-en" || id === "da-1-malay") {
          if (industryDA === "product") {
            id += "-product";
          } else if (industryDA === "service") {
            id += "-service";
          }
        } else if (id === "da-2-cn-combined" || id === "da-2-en-combined" || id === "da-2-malay-combined") {
          credit = 0.1;
        }
      }
  
      if (!query) continue;
  
      let payload = { industryDA, selfDefinedValue, id, query, credit }
  
      // Call the fetchDataCombinedADDA function
      const promise = fetchDataCombinedADDA(payload, auth, setMessages, setError, setLoading, setTyping, typingInterval);
      toast.promise(
        promise,
        {
          loading: `Generating ${IdIdentifierGeneral({ id: id })}...`,
          success: <b>Done Generate for {IdIdentifierGeneral({ id: id })}!</b>,
          error: <b>Generate Failed.</b>,
        }
      );
      try {
        await promise;
      } catch (err) {
        console.error(err);
      }
    }
  }

  return (
    <>
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Toaster position="bottom-right" reverseOrder={false} />
        <Grid container={true} spacing={4}>

          {/* <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="fixed"
            left={0}
            top={0}
            bottom={0}
            pl={2}
          >
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={handleClickOpen}
              className={classes.rotatedButton}
            >
              Open Slide
            </Button>
          </Box>           */}

          <Grid item={true} xs={12} md={12}>
            <Card>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                <Typography variant="h5">Output</Typography>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleClickOpen}
                  className={classes.rotatedButton}
                >
                  Enter Info
                </Button>
              </Box>
              <Divider />
              <CardContent className={classes.cardContent}>
                <Box>
                  <OutputContainerCombinedADDA 
                      typing={typing}
                      load={loading}
                      error={error}
                      messages={messages}
                      id="chat_container"
                      handleSubmit={handleSubmit}
                    />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      
    </Section>
    <Dialog
      open={openDialog}
      TransitionComponent={Slide}
      // keepMounted
      onClose={handleClose}
      // aria-labelledby="alert-dialog-slide-title"
      // aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="lg"
      className={classes.dialog}
    >
      <CombinedADDAItems 
        handleSubmit={handleSubmit}
        tone={tone}
        productName={productName}
        productUSP={productUSP}
        productContent={productContent}
        onProductNameChange={handleProductNameChange}
        onProductUSPChange={handleProductUSPChange}
        onProductContentChange={handleProductContentChange}
        onToneChange={handleToneChange}
        legacyMode={legacyMode} 
        onLegacyModeChange={handleLegacyModeChange}

        industryDA={industryDA}
        productIngredient={productIngredient}
        productCert={productCert}
        onIndustryDAChange={handleIndustryDAChange}
        onProductIngredientChange={handleProductIngredientChange}
        onProductCertChange={handleProductCertChange}
      />
      <Button onClick={handleClose}>Close</Button>
    </Dialog>
    </>
  );
}

export default CombinedADDASection;