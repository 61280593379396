import { useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textEditorContainer: {
    border: "1px solid black",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.type === 'dark' ? '#424242' : '#fff',
    color: theme.palette.type === 'dark' ? 'white' : 'black',
    "&:focus": {
      outline: "none",
    },
    height: "100%",
  },
  editorContent: {
    "& .ProseMirror": {
      backgroundColor: theme.palette.type === 'dark' ? '#424242' : '#fff',
      color: theme.palette.type === 'dark' ? 'white' : 'black',
    },
    "& .ProseMirror:focus": {
      outline: "none",
    },
    height: "100%",
    overflowY: "auto",
  },
}));

export const TextEditor = ({ script, isEditMode, onContentChange }) => {
  // console.log('isEditMode:', isEditMode);
  const classes = useStyles();
  const editor = useEditor({
    extensions: [StarterKit],
    content: script,
    editable: true,
  });

  useEffect(() => {
    if (editor) {
      editor.on("update", () => {
        const html = editor.getHTML();
        onContentChange(html);
      });
    }
  }, [editor, onContentChange]);

  useEffect(() => {
    if (editor && script !== editor.getHTML()) {
      editor.commands.setContent(script);
    }
  }, [script, editor]);

  return editor ? (
    <div className={classes.textEditorContainer}>
      <EditorContent className={classes.editorContent} editor={editor} />
    </div>
  ) : null;
};
