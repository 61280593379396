import React from "react";
import Meta from "../components/Meta";
import { requireProUser } from "../util/auth";
import CombinedPEPCSection from "../components/CombinedPEPCSection";

function CombinedPEPCPage(props) {
  return (
    <>
      <Meta title="Combined PEPC" />
      <CombinedPEPCSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Combined PEPC"
        subtitle="PEPC整合"
      />
    </>
  );
}

export default requireProUser(CombinedPEPCPage);