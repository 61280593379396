import React, { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Button, Dialog, Divider, Slide } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import '../style/container.css'
import { Toaster, toast } from "react-hot-toast";
import { useAuth } from "../util/auth";
import { chatContext } from "../context/ChatContext"
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import CombinedPEPCItems from "./CombinedPEPCItems";
import { IdIdentifierGeneral } from "./functionalComponent/IdIdentifier";
import { fetchData, fetchDataCombinedPEPC, OutputContainer, OutputContainerCombinedPEPC } from "./functionalComponent/fetchData";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "885px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
  },
  ay: {
  },
  copy: {
    display: ({ typing, load, error }) => typing || load || error ? "none" : "block",
    cursor: "pointer",
    "& img": { width: "22px" },
  },
  rotatedButton: {
    // transform: "rotate(-90deg)",
    padding: theme.spacing(2),
    fontSize: '1rem',
  },
  dialog: {
    // alignItems: "flex-end !important",
    maxHeight: "95vh",
  }
}));

function CombinedPEPCSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState(false);
  const [openDialog, setOpenDialog] = useState(true);

  const {
    setIsChatOpen,
    setClearChat,
    typingInterval,
    selfDefinedValue,
  } = useContext(chatContext);

  const [productName, setProductName] = useState("");
  const [productUSP, setProductUSP] = useState("");
  const [painPoint, setPainPoint] = useState("");
  const [productEndResult, setProductEndResult] = useState("");
  const [callToAction, setCallToAction] = useState(""); 
  const [introduce, setIntroduce] = useState("");
  const [legacyMode, setLegacyMode] = useState(false);

  // State to manage the list of messages
  const placeholderMessages = [
    { 
      uniqueId: 'pepc-hook-cn', 
      pepc_id: 'pepc-hook-cn',
      isUser: false, 
      value: '', 
      loading: true 
    },
    { 
      uniqueId: 'pepc-intro-cn',
      pepc_id: 'pepc-intro-cn', 
      isUser: false, 
      value: '', 
      loading: true 
    },
    { 
      uniqueId: 'pepc-usp-result-cn',
      pepc_id: 'pepc-usp-result-cn', 
      isUser: false, 
      value: '', 
      loading: true 
    },
    { 
      uniqueId: 'pepc-cta-cn',
      pepc_id: 'pepc-cta-cn', 
      isUser: false, 
      value: '', 
      loading: true 
    }
  ];

  // Initialize messages state with placeholders
  const [messages, setMessages] = useState(placeholderMessages);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleLegacyModeChange = (newLegacyMode) => {
    setLegacyMode(newLegacyMode);
  };

  const handleProductNameChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductName(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleProductUSPChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductUSP(event.target && event.target.value ? event.target.value : event);
    }
  };
  
  const handlePainPointChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setPainPoint(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleProductEndResultChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductEndResult(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleCallToActionChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setCallToAction(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleIntroduceChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setIntroduce(event.target && event.target.value ? event.target.value : event);
    }
  };

  let query;

  async function handleSubmit(ids, e, lastInput = false, speechRes = false, credit = 0.4) {
    e && e.preventDefault();
    e && e.stopPropagation();
    handleClose();
    setLoading(true);
    setIsChatOpen(true); 
    setClearChat(false);

    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    console.log(ids);
    // Iterate over each id and call fetchData
    for (let id of ids) {
      const query = `Product info: \n
        The product name and type is: ${productName} \n
        Unique Selling Point is: ${productUSP} \n
        Pain Point is: ${painPoint} \n
        End Result is: ${productEndResult} \n
        Call to Action is: ${callToAction} \n
        Introduction is: ${introduce}`;

      let payload = { selfDefinedValue, id, query, credit }

      const promise = fetchDataCombinedPEPC(payload, auth, setMessages, setError, setLoading, setTyping, typingInterval);

      toast.promise(
        promise,
        {
          loading: `Generating ${IdIdentifierGeneral({ id: id })}...`,
          success: <b>Done Generate for {IdIdentifierGeneral({ id: id })}!</b>,
          error: <b>Generate Failed.</b>,
        }
      );

      try {
        await promise;
      } catch (err) {
        console.error(err);
      }
    }
  }

  return (
    <>
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={4}
            textAlign="center"
          />
          <Toaster position="bottom-right" reverseOrder={false} />
          <Grid container={true} spacing={4}>
            <Grid item={true} xs={12} md={12}>
              <Card>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  padding={2}
                >
                  <Typography variant="h5">Output</Typography>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={handleClickOpen}
                    className={classes.rotatedButton}
                  >
                    Enter Info
                  </Button>
                </Box>
                <Divider />
                <CardContent className={classes.cardContent}>
                  <Box>
                    <OutputContainerCombinedPEPC 
                      typing={typing}
                      load={loading}
                      error={error}
                      messages={messages}
                      id="chat_container"
                      handleSubmit={handleSubmit}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Dialog
        open={openDialog}
        TransitionComponent={Slide}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        className={classes.dialog}
      >
        <CombinedPEPCItems 
          handleSubmit={handleSubmit}
          productName={productName}
          productUSP={productUSP}
          painPoint={painPoint}
          productEndResult={productEndResult}
          callToAction={callToAction}
          introduce={introduce}
          onProductNameChange={handleProductNameChange}
          onProductUSPChange={handleProductUSPChange}
          onPainPointChange={handlePainPointChange}
          onProductEndResultChange={handleProductEndResultChange}
          onCallToActionChange={handleCallToActionChange}
          onIntroduceChange={handleIntroduceChange}
          legacyMode={legacyMode} 
          onLegacyModeChange={handleLegacyModeChange}
        />
        <Button onClick={handleClose}>Close</Button>
      </Dialog>
    </>
  );
}

export default CombinedPEPCSection;