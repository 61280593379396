import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import PepcProblemSection from "../../components/drama/PepcProblemSection";

function PepcProblemPage(props) {
  return (
    <>
      <Meta title="剧本 PEPC" />
      <PepcProblemSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="剧本 PEPC"
        subtitle="Problem"
      />
    </>
  );
}

export default requireProUser(PepcProblemPage);
