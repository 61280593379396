import React from "react";
import Meta from "../components/Meta";
import { requireProUser } from "../util/auth";
import SongCreationSection from "../components/piapi/SongCreationSection";

function SongCreationPage(props) {
  return (
    <>
      <Meta title="Song Creation" />
      <SongCreationSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Song Creation"
        subtitle="歌曲创作"
      />
    </>
  );
}

export default requireProUser(SongCreationPage);