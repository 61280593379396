import React, { useState, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import {
  saveInputs,
  retrieveInputs,
  resetInput,
  legacySavedOptionsMethod,
} from "../util/db";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Popper,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Toaster, toast } from "react-hot-toast";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import SavedReplySection from "./SavedReplySection";
import { useRefresh } from "../context/RefreshContext";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import { LegacyModeSwitch, TruncateSelect, TruncateAutocomplete } from "./functionalComponent/LegacyModeSwitch";
import LanguageOutput from './functionalComponent/LanguageOutput';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "1150px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  truncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "500px",
  },
}));

function ReplyItems(props) {
  const { handleSubmit } = props;
  const {
    onProductNameChange,
    onProductUSPChange,
    onProductIngredientChange,
    onProductEndResultChange,
    onPainPointChange,
    onProductPromoChange,
  } = props;

  const classes = useStyles();
  const auth = useAuth();
  const { refresh } = useRefresh();
  const { t, i18n } = useTranslation();
  // default input declaration
  const [productName, setProductName] = useState([]);
  const [productUSP, setProductUSP] = useState([]);
  const [productIngredient, setProductIngredient] = useState([]);
  const [productEndResult, setProductEndResult] = useState([]);
  // default input legacy declaration
  const [legacyMode, setLegacyMode] = useState(false);
  const [productNamelegacy, setProductNamelegacy] = useState([]);
  const [productUSPlegacy, setProductUSPlegacy] = useState([]);
  const [productIngredientlegacy, setProductIngredientlegacy] = useState([]);
  const [productEndResultlegacy, setProductEndResultlegacy] = useState([]);
  const [savedInputs, setSavedInputs] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [uspOptions, setUSPOptions] = useState([]);
  const [ingredientOptions, setIngredientOptions] = useState([]);
  const [endResultOptions, setEndResultOptions] = useState([]);
  // savedInputs dialog
  const [openDialog, setOpenDialog] = useState(false);
  // multiple languages support
  const [selectedLanguage, setSelectedLanguage] = useState("Chinese");
  // button group declaration
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const replyCatOptions = [
    "Credibility (1) (0.4)",
    "Credibility (2) (0.4)",
    "Credibility (3) (0.4)",
  ];
  const ids = ["acca-cred-1", "acca-cred-2", "acca-cred-3"];

  // Handles the clearing of inputs
  const clearInputs = () => {
    setProductName([]);
    setProductUSP([]);
    setProductIngredient([]);
    setProductEndResult([]);
  };

  // Handles the legacy mode change
  const handleLegacyModeChange = (newValue) => {
    setLegacyMode(newValue);
  };

  // Handles the dialog toggle for savedInputs
  const handleDialogToggle = () => {
    setOpenDialog(!openDialog);
  };

  // Handles the handleSubmit options toggle
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // Handles the handleSubmit options to close
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // Handles the handleSubmit id for different languages
  const handleMenuItemClick = async (event, index) => {
    let id = ids[index];
    if (selectedLanguage === "Chinese") {
      id += "-cn";
    } else if (selectedLanguage === "English") {
      id += "-en";
    } else if (selectedLanguage === "Malay") {
      id += "-malay";
    }
    await handleSubmit(id, event);
    legacyMode && handleSaveInputs();
    setOpen(false);
  };

  // Save inputs from the text fields (Only in Legacy Mode)
  const handleSaveInputs = () => {
    const inputsArray = [
      { key: "product_name", value: productNamelegacy },
      { key: "usp", value: productUSPlegacy },
      { key: "product_ingredient", value: productIngredientlegacy },
      { key: "end_result", value: productEndResultlegacy },
    ];
    // Check if any of the values are undefined
    const hasUndefinedValues = inputsArray.some(
      (input) => input.value === undefined
    );
    if (hasUndefinedValues) {
      console.error("Some input values are undefined");
      return;
    }
    // Filter out inputs that already exist in the retrieved inputs
    const uniqueInputs = inputsArray.filter(
      (newInput) =>
        !savedInputs.some(
          (savedInput) =>
            savedInput.key === newInput.key &&
            savedInput.value === newInput.value
        )
    );
    // If all inputs are duplicates, stop execution
    if (uniqueInputs.length === 0) {
      console.log("No new inputs to save");
      return;
    }
    // Execute savedInputs
    saveInputs(auth.user.uid, uniqueInputs).then((savedKeys) => {
      handleRetrieveInputs(); // Retrieve the inputs again after saving
      if (savedKeys == undefined) {
        toast.success("Input has been saved");
      }
    });
  };

  // Delete inputs from the dropdown (Only in Legacy Mode)
  const handleDeleteInput = (inputId, inputValue) => {
    resetInput(auth.user.uid, inputId, inputValue)
      .then(() => {
        handleRetrieveInputs();
      })
      .catch((error) => {
        console.error("Error resetting input:", error);
      });
  };

  // Handles the retrieval of savedInputs
  const handleRetrieveInputs = async () => {
    try {
      const inputs = await retrieveInputs(auth.user.uid);
      const formattedInputs = inputs.map((input, index) => ({
        ...input,
      }));
      setSavedInputs(formattedInputs);
    } catch (error) {
      console.error("Error retrieving inputs:", error);
    }
  };

  // useEffect for retrieve legacy mode status and set savedInputs 
  useEffect(async () => {
    const fetchLegacyMode = async () => {
      const legacyModeValue = await legacySavedOptionsMethod(auth.user.uid);
      setLegacyMode(legacyModeValue);
      props.onLegacyModeChange(legacyModeValue);
    };
    fetchLegacyMode();
    handleRetrieveInputs();
  }, []);

  // useEffect for retrieve and set savedInputs after refresh
  useEffect(() => {
    handleRetrieveInputs();
  }, [refresh]);

  // useEffect to set savedInputs into options
  useEffect(() => {
    setNameOptions(
      savedInputs
        .filter((input) => input.key === "product_name")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
    );

    setUSPOptions(
      savedInputs
        .filter((input) => input.key === "usp")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
    );

    setIngredientOptions(
      savedInputs
        .filter((input) => input.key === "product_ingredient")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
    );

    setEndResultOptions(
      savedInputs
        .filter((input) => input.key === "end_result")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
    );
  }, [savedInputs]);
  
  // Only when legacy mode is false
  const onNameSelectChange = (event) => {
    setProductName(event.target.value);
    const nameValues = Array.isArray(event.target.value)
      ? event.target.value.map((item) => item.value).join(" ")
      : event.target.value;
    onProductNameChange(nameValues);
  };
  
  const onUSPSelectChange = (event) => {
    setProductUSP(event.target.value);
    const uspValues = Array.isArray(event.target.value)
      ? event.target.value.map((item) => item.value).join(" ")
      : event.target.value;
    onProductUSPChange(uspValues);
  };
  
  const onIngredientSelectChange = (event) => {
    setProductIngredient(event.target.value);
    const ingredientValues = Array.isArray(event.target.value)
      ? event.target.value.map((item) => item.value).join(" ")
      : event.target.value;
    onProductIngredientChange(ingredientValues);
  };
  const onEndResultSelectChange = (event) => {
    setProductEndResult(event.target.value);
    const endResultValues = Array.isArray(event.target.value)
    ? event.target.value.map((item) => item.value).join(" ")
    : event.target.value;
    onProductEndResultChange(endResultValues);
  };

  return (
    <>
      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Toaster position="bottom-right" reverseOrder={false} />
          <Typography variant="h5">Generator</Typography>
          <Box>
            {!legacyMode && (
              <>
                <Tooltip title="Clear Inputs">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={clearInputs}
                    style={{ marginLeft: "1rem" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={handleDialogToggle}
                    style={{ marginLeft: "1rem" }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}

            <LegacyModeSwitch
              auth={auth}
              initialLegacyMode={legacyMode}
              onLegacyModeChange={handleLegacyModeChange}
            />
          </Box>
        </Box>
        <Divider />

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 1: 输入品牌名字和卖的产品/服务 */}
            Step 1: {t("CPW006")}
          </Typography>
          {legacyMode ? ( // Render AutoComplete when legacyMode is true
            <TruncateAutocomplete
              options={nameOptions}
              value={productNamelegacy}
              onChange={(value) => {
                setProductNamelegacy(value);
                onProductNameChange({ target: { value } });
              }}
              onDelete={(value) => handleDeleteInput("Product Name", value)}
              // placeholder="输入品牌名字和卖的产品/服务"
              placeholder={t("CPW001") + t("CPW006")}
              minRowsValue={2}
              maxRowsValue={2}
            />
          ) : (
            // Render Select when legacyMode is false
            <TruncateSelect
              options={nameOptions}
              value={productName}
              onChange={onNameSelectChange}
              // label="Product Name"
              label={t("CPW006")}
              id="product_name"
            />
          )}
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 2: 针对的市场痛点/市场需求(required) */}
            Step 2: {t("CPW024")}
          </Typography>
          <TextField
            onChange={onPainPointChange}
            value={props.painPoint}
            margin="dense"
            name="painPoint"
            fullWidth
            variant="outlined"
            // placeholder="针对的市场痛点/市场需求"
            placeholder={t("CPW001") + t("CPW024")}
            multiline
            minRows={1}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 3: 输入品牌卖点的数据(required) */}
            Step 3: {t("CPW015")}
          </Typography>
          {legacyMode ? ( // Render AutoComplete when legacyMode is true
            <TruncateAutocomplete
              options={uspOptions}
              value={productUSPlegacy}
              onChange={(value) => {
                setProductUSPlegacy(value);
                onProductUSPChange({ target: { value } });
              }}
              onDelete={(value) => handleDeleteInput("USP", value)}
              // placeholder="输入品牌卖点的数据"
              placeholder={t("CPW001") + t("CPW015")}
              minRowsValue={3}
              maxRowsValue={3}
            />
          ) : (
            // Render Select when legacyMode is false
            <TruncateSelect
              options={uspOptions}
              value={productUSP}
              onChange={onUSPSelectChange}
              // label="USP"
              label={t("CPW015")}
              id="usp"
            />
          )}
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 4: 输入独特成分/材料/制作方式/科技(required) */}
            Step 4: {t("CPW022")}
          </Typography>
          {legacyMode ? ( // Render AutoComplete when legacyMode is true
            <TruncateAutocomplete
              options={ingredientOptions}
              value={productIngredientlegacy}
              onChange={(value) => {
                setProductIngredientlegacy(value);
                onProductIngredientChange({ target: { value } });
              }}
              onDelete={(value) => handleDeleteInput("Product Ingredient", value)}
              // placeholder="输入独特成分/材料/制作方式/科技"
              placeholder={t("CPW001") + t("CPW022")}
              minRowsValue={3}
              maxRowsValue={3}
            />
          ) : (
            // Render Select when legacyMode is false
            <TruncateSelect
              options={ingredientOptions}
              value={productIngredient}
              onChange={onIngredientSelectChange}
              // label="独特成分/材料/制作方式/科技"
              label={t("CPW022")}
              id="ingredient"
            />
          )}
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 4: 输入产品功效(required) */}
            Step 5: {t("CPW025")}
          </Typography>
          {legacyMode ? ( // Render AutoComplete when legacyMode is true
            <TruncateAutocomplete
              options={endResultOptions}
              value={productEndResultlegacy}
              onChange={(value) => {
                setProductEndResultlegacy(value);
                onEndResultSelectChange({ target: { value } });
              }}
              onDelete={(value) => handleDeleteInput("End Result", value)}
              // placeholder="输入产品功效"
              placeholder={t("CPW001") + t("CPW025")}
              minRowsValue={2}
              maxRowsValue={2}
            />
          ) : (
            // Render Select when legacyMode is false
            <TruncateSelect
              options={endResultOptions}
              value={productEndResult}
              onChange={onEndResultSelectChange}
              // label="输入产品功效"
              label={t("CPW025")}
              id="endresult"
            />
          )}
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 5: 输入产品优惠配套(required for CTA) */}
            Step 6: {t("CPW026")}
          </Typography>
          <TextField
            onChange={onProductPromoChange}
            value={props.productPromo}
            margin="dense"
            name="productPromo"
            fullWidth
            variant="outlined"
            // placeholder="输入产品优惠配套"
            placeholder={t("CPW001") + t("CPW026")}
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        {/* not used */}
        <Box display="flex" flexDirection="column" py={1} px={3}>
          <Typography component="span">
            {/* Attention Model Selection */}
            Step 7: {"选择模型"}
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="model"
              name="model"
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={t("CPW098")}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={t("CPW099")}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        {/* <LanguageOutput selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} /> */}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          py={2}
          px={3}
        >
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={async (e) => {
              let awareId;
              if (selectedLanguage === "Chinese") {
                awareId = "acca-aware-cn";
              } else if (selectedLanguage === "English") {
                awareId = "acca-aware-en";
              } else if (selectedLanguage === "Malay") {
                awareId = "acca-aware-malay";
              }
              await handleSubmit(awareId, e);
              legacyMode && handleSaveInputs();
            }}
          >
            Awareness (0.4)
          </Button>

          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={async (e) => {
              let consId;
              if (selectedLanguage === "Chinese") {
                consId = "acca-cons-cn";
              } else if (selectedLanguage === "English") {
                consId = "acca-cons-en";
              } else if (selectedLanguage === "Malay") {
                consId = "acca-cons-malay";
              }
              await handleSubmit(consId, e);
              legacyMode && handleSaveInputs();
            }}
          >
            Consideration (0.4)
          </Button>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          py={2}
          px={3}
        >
          {/* Second Button Group */}
          <ButtonGroup
            variant="contained"
            color="primary"
            ref={anchorRef}
            aria-label="split button"
          >
            <Button onClick={handleToggle}>Credibility (0.4)</Button>
            <Button
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select option"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              ▼
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {replyCatOptions.map((option, index) => (
                        <MenuItem
                          key={option}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={async (e) => {
              let actionId;
              if (selectedLanguage === "Chinese") {
                actionId = "acca-action-cn";
              } else if (selectedLanguage === "English") {
                actionId = "acca-action-en";
              } else if (selectedLanguage === "Malay") {
                actionId = "acca-action-malay";
              }
              await handleSubmit(actionId, e);
              legacyMode && handleSaveInputs();
            }}
          >
            Action (0.4)
          </Button>
        </Box>

      </Paper>

      {/* Dialog for users to edit saved replies */}
      <Dialog
        open={openDialog}
        onClose={handleDialogToggle}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {/* Edit Saved Replies */}
          {t("CPW003")}
        </DialogTitle>
        <DialogContent>
          <SavedReplySection />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ReplyItems;
