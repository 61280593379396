import React from "react";
import Meta from "../components/Meta";
import SavedProfileSection from "../components/SavedProfileSection";
import { useRouter } from "../util/router";
import { requireAuth } from "../util/auth";
import Box from "@material-ui/core/Box";

function ProfilePage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Saved Profiles" />
      <Box p={6}>
        <SavedProfileSection />
      </Box>
    </>
  );
}

export default requireAuth(ProfilePage);
