import React, { useContext, useState, useRef, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import ReplaceItems from "./ReplaceItems";
import { useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import { chatContext } from "../context/ChatContext";
import "../style/container.css";
import generateUniqueId from "../util/generateuniqueid";
import ChatStripe from "./functionalComponent/ChatStripe2";
import { Divider } from "@material-ui/core";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { firebaseApp } from "../util/firebase";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "755px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  loadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
}));

function ReplaceSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState(false);

  const {
    setIsChatOpen,
    setClearChat,
    loadingInterval,
    typingInterval,
    selfDefinedValue,
  } = useContext(chatContext);

  // const formRef = useRef(null);
  const chat_container_ref = useRef(null);
  const [lastUniqueId, setLastUniqueId] = useState("");

  const [imagePrompt, setImagePrompt] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [imageData, setImageData] = useState("");
  // const [imageWidth, setImageWidth] = useState(null);
  // const [imageHeight, setImageHeight] = useState(null);
  // const [inputWidth, setInputWidth] = useState(null);
  // const [inputHeight, setInputHeight] = useState(null);
  const [transformParameters, setTransformParameters] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [theme, setTheme] = useState("");
  const [description, setDescription] = useState("");
  const [position, setPosition] = useState("");

  // Positioning States
  const [leftX, setLeftX] = useState(0);
  const [rightX, setRightX] = useState(0);
  const [centerX, setCenterX] = useState(0);

  // Color and theme States
  const [colorCode, setColorCode] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);

  // State to manage the list of messages
  const [messages, setMessages] = useState([]);

  let query;

  query = imagePrompt;

  const handleThemeChange = (event) => {
    setDescription("");
    setTheme(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleColorChange = (newColor) => {
    setColorCode(newColor);
  };

  const handleColorPickerChange = (event) => {
    setColorCode("");
    setShowColorPicker(!showColorPicker);
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
    setTransformParameters((prevState) => ({
      ...prevState,
      x:
        event.target.value === "Center"
          ? centerX
          : event.target.value === "Left"
          ? leftX
          : rightX,
    }));
  };

  const calculateTransformParameters = (inputWidth, inputHeight) => {
    if (inputWidth > inputHeight) {
      let scaleFactor = 500 / inputWidth;
      return scaleFactor;
    } else {
      let scaleFactor = 500 / inputHeight;
      return scaleFactor;
    }
  };

  const cropImage = (img) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = img.width;
    canvas.height = img.height;

    context.drawImage(img, 0, 0);

    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    let top = null,
      bottom = null,
      left = imageData.width,
      right = null;

    // Scan from top to bottom and left to right
    for (let y = 0; y < imageData.height; y++) {
      for (let x = 0; x < imageData.width; x++) {
        const alpha = data[(x + y * imageData.width) * 4 + 3];
        if (alpha !== 0) {
          if (top === null) top = y; // Set the top edge
          if (x < left) left = x; // Adjust the left edge
          if (x > right || right === null) right = x; // Adjust the right edge
          bottom = y; // Adjust the bottom edge
        }
      }
    }

    // Now crop the image based on the bounds found
    const croppedCanvas = document.createElement("canvas");
    croppedCanvas.width = right - left + 1;
    croppedCanvas.height = bottom - top + 1;
    croppedCanvas
      .getContext("2d")
      .drawImage(
        canvas,
        left,
        top,
        croppedCanvas.width,
        croppedCanvas.height,
        0,
        0,
        croppedCanvas.width,
        croppedCanvas.height
      );
    return croppedCanvas.toDataURL(); // Returns the base64 data of the cropped image
  };

  const uploadImage = async (file) => {
    const storage = getStorage(firebaseApp);
    const storageRef = ref(
      storage,
      `users/${auth.user.uid}/productInput/${file.name}`
    );
    // Check if file already exists
    // can't use an if statement in this case because
    // getDownloadURL() does not return a false or null value when the file does not exist, instead it throws an error.
    try {
      const existingImageUrl = await getDownloadURL(storageRef);
      // If the file exists, return its URL without uploading
      console.log("File exists");
      return existingImageUrl;
    } catch (error) {
      // File doesn't exist, proceed with upload
    }

    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error("Failed to upload image to Firebase:", error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              console.log("File available at", downloadURL);
              resolve(downloadURL); // resolving the promise with the downloadURL
            })
            .catch((error) => {
              console.error("Failed to get download URL:", error);
              reject(error);
            });
        }
      );
    });
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        const trimmedBase64Data = reader.result.trim();
        setImageData(trimmedBase64Data);
        const imageSizeInBytes = 4 * Math.ceil(reader.result.length / 3);
        const imageSizeInMegabytes = imageSizeInBytes / (1024 * 1024);
        console.log("Image size in MB:", imageSizeInMegabytes);

        // Create an Image and return a Promise
        const loadImage = (src) => {
          return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(image);
            image.onerror = reject;
            image.src = src;
          });
        };

        // Load the image
        loadImage(reader.result).then((image) => {
          // Save original image width and height
          const imageWidth = image.width;
          const imageHeight = image.height;

          const croppedData = cropImage(image);

          // Create a new image element with the cropped data
          const croppedImage = new Image();
          croppedImage.onload = () => {
            // Get input width and height
            const inputWidth = croppedImage.width;
            const inputHeight = croppedImage.height;
            const scaleFactor = calculateTransformParameters(
              inputWidth,
              inputHeight
            );

            // Whitespace width
            const whiteSpaceWidth =
              scaleFactor * ((imageWidth - inputWidth) / 2);
            const whiteSpaceHeight =
              scaleFactor * ((imageHeight - inputHeight) / 2);

            // Result product image width
            const resultWidth = scaleFactor * imageWidth;

            // Position Center
            const centerX = (1024 - resultWidth) / 2;
            setCenterX(centerX);

            // Position Left
            const leftX = 102.4 - whiteSpaceWidth;
            setLeftX(leftX);

            // Position Right
            const rightX =
              1024 - scaleFactor * imageWidth - 102.4 - whiteSpaceWidth;
            setRightX(rightX);

            // Set the transformation parameters to the state
            setTransformParameters({
              scale_x: scaleFactor,
              scale_y: scaleFactor,
              x: rightX,
              y: 400 - whiteSpaceHeight,
              angle: 0,
            });
          };
          croppedImage.src = croppedData;
        });
      };
      reader.readAsDataURL(file);

      try {
        const imageUrl = await uploadImage(file); // await the Promise to get the download URL
        setImageUrl(imageUrl);
      } catch (error) {
        console.error("Failed to upload image:", error);
      }
    }
  };

  const [queue, setQueue] = useState([]);
  let isProcessing = useRef(false);

  useEffect(() => {
    if (!isProcessing.current && queue.length > 0) {
      processQueue();
    }
  }, [queue]);

  function handleSubmit(id, e, lastInput = false) {
    e && e.preventDefault();
    setLoading(true);
    setIsChatOpen(true);
    setClearChat(false);

    // bot's chatStripe
    let uniqueId;
    uniqueId = generateUniqueId();

    setMessages((oldMessages) => [
      {
        isUser: false,
        value: "",
        error: false,
        uniqueId: uniqueId,
        loading: true,
      },
      ...oldMessages,
    ]);

    setQueue((oldQueue) => [...oldQueue, { id, e, lastInput, uniqueId }]);
  }

  async function processQueue() {
    if (queue.length === 0) {
      isProcessing.current = false;
      return;
    }

    isProcessing.current = true;
    const { id, e, lastInput, uniqueId } = queue[0];
    await handleFetch(id, e, lastInput, uniqueId);
    setQueue((oldQueue) => oldQueue.slice(1));
    isProcessing.current = false;
  }

  async function handleFetch(id, e, lastInput = false, uniqueId) {
    // fetching data from server
    return fetch("https://ctg-api.onrender.com/v2_replace", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prompt: imagePrompt,
        name: auth.user.displayName,
        uid: auth.user.uid,
        email: auth.user.email,
        imageUrl: imageUrl,
        transformParameters: transformParameters,
        theme: theme,
        description: description,
        ...(colorCode && { color: colorCode }),
      }),
    })
      .then(async (response) => {
        console.log("Request completed", response);
        if (response.ok) {
          setError(false);
          const data = await response.json();
          const parsedData = data.imageData;

          if (parsedData === "") {
            setMessages((oldMessages) => [
              {
                isUser: false,
                value: "No results Found 😞",
                error: false,
                uniqueId: uniqueId,
              },
              ...oldMessages,
            ]);
          } else {
            // Create an img element with the imageUrl as its src
            const imageElement = (
              <img
                src={parsedData}
                alt="Generated image"
                style={{ width: "100%", height: "100%" }}
              />
            );
            setTyping(true);
            setTimeout(() => {
              setMessages((oldMessages) =>
                oldMessages.map((message) =>
                  message.uniqueId === uniqueId
                    ? {
                        ...message,
                        value: imageElement,
                        loading: false,
                      }
                    : message
                )
              );
              setTyping(false);
            }, typingInterval);
          }
        } else {
          setError(true);

          setMessages((oldMessages) =>
            oldMessages.map((message) =>
              message.uniqueId === uniqueId
                ? {
                    ...message,
                    value: "An error occurred. Try again later",
                    error: true,
                    loading: false,
                  }
                : message
            )
          );
        }
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        setMessages((oldMessages) =>
          oldMessages.map((message) =>
            message.uniqueId === uniqueId
              ? {
                  ...message,
                  value: "Sorry, server is down right now, try again later",
                  error: true,
                  loading: false,
                }
              : message
          )
        );
      });
  }

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        <Grid container={true} spacing={4}>
          {/* Left Grid */}
          <Grid item={true} xs={12} md={6}>
            <ReplaceItems
              handleSubmit={handleSubmit}
              onImageUploadChange={handleImageUpload}
              onThemeChange={handleThemeChange}
              onDescriptionChange={handleDescriptionChange}
              onPositionChange={handlePositionChange}
              onColorChange={handleColorChange}
              setPreviewImage={setPreviewImage}
              previewImage={previewImage}
              colorCode={colorCode}
              theme={theme}
              description={description}
              position={position}
              onColorPickerChange={handleColorPickerChange}
              showColorPicker={showColorPicker}
            />
          </Grid>

          {/* Right Grid */}
          <Grid item={true} xs={12} md={6}>
            <Card>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                <Typography variant="h5">Output</Typography>
              </Box>
              <Divider />
              <CardContent className={classes.cardContent}>
                <Box>
                  <OutputContainer
                    typing={typing}
                    load={loading}
                    error={error}
                    messages={messages}
                    id="chat_container"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default ReplaceSection;

function OutputContainer({ typing, error, messages, id }) {
  const classes = useStyles({ typing, error });

  return (
    <div id={id}>
      {messages.map((message, index) => (
        <ChatStripe
          key={message.uniqueId || index}
          isAi={!message.isUser}
          value={message.value}
          uniqueId={message.uniqueId}
          showIcon={true}
          loading={message.loading}
          noCopy={true}
        />
      ))}

      <div className={classes.ai}></div>
      <div className={classes.copy}></div>
    </div>
  );
}
