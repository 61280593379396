import React, { useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Container,
  Typography,
  Button,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-hot-toast";
import { useAuth } from "../../util/auth";
import Section from "../Section";
import SectionHeader from "../SectionHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "400px",
    overflowY: "auto",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  outputText: {
    whiteSpace: "pre-wrap",
    fontFamily: "monospace",
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
}));

function SongCreationSection() {
  const classes = useStyles();
  const auth = useAuth();
  const [prompt, setPrompt] = useState("");
  const [lyrics, setLyrics] = useState("");
  const [isGeneratingLyrics, setIsGeneratingLyrics] = useState(false);
  const [isGeneratingSong, setIsGeneratingSong] = useState(false);
  const [makeInstrumental, setMakeInstrumental] = useState(false);
  const [songTaskId, setSongTaskId] = useState(null);

  const handleGenerateLyrics = async () => {
    if (!prompt) {
      toast.error("Please enter a prompt");
      return;
    }

    if (!auth.user) {
      toast.error("Please login first");
      return;
    }

    setIsGeneratingLyrics(true);
    try {
      const response = await fetch("https://ctg-api.onrender.com/songCreation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt,
          action: "generateLyrics",
          name: auth.user.name,
          uid: auth.user.uid,
          email: auth.user.email,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setLyrics(data.lyrics);
        toast.success("Lyrics generated successfully!");
      } else {
        throw new Error(data.message || "Failed to generate lyrics");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsGeneratingLyrics(false);
    }
  };

  const handleCreateSong = async () => {
    if (!lyrics) {
      toast.error("Please generate lyrics first");
      return;
    }

    if (!auth.user) {
      toast.error("Please login first");
      return;
    }

    setIsGeneratingSong(true);
    try {
      // const response = await fetch("https://ctg-api.onrender.com/songCreation", {
      const response = await fetch("http://localhost:5001/songCreation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: lyrics,
          action: "createSong",
          makeInstrumental,
          name: auth.user.name,
          uid: auth.user.uid,
          email: auth.user.email,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setSongTaskId(data.task_id);
        toast.success("Song creation task initiated!");
      } else {
        throw new Error(data.message || "Failed to create song");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsGeneratingSong(false);
    }
  };

  return (
    <Section className={classes.root}>
      <Container>
        {/* <SectionHeader
          title="AI Song Creation"
          subtitle="Generate lyrics and create songs using AI"
        /> */}
        
        <Grid container spacing={4}>
          {/* Lyrics Generation Section */}
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h6" gutterBottom>
                  Generate Lyrics
                </Typography>
                <TextField
                  className={classes.textField}
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Enter your prompt"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                />
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleGenerateLyrics}
                  disabled={isGeneratingLyrics}
                >
                  {isGeneratingLyrics ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Generate Lyrics"
                  )}
                </Button>
              </CardContent>
            </Card>
          </Grid>

          {/* Generated Lyrics Display */}
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h6" gutterBottom>
                  Generated Lyrics
                </Typography>
                <Typography className={classes.outputText}>
                  {lyrics || "Your generated lyrics will appear here..."}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Song Creation Section */}
          <Grid item xs={12}>
            <Divider className={classes.divider} />
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Create Song
                </Typography>
                <FormControl variant="outlined" fullWidth className={classes.textField}>
                  <InputLabel>Song Type</InputLabel>
                  <Select
                    value={makeInstrumental}
                    onChange={(e) => setMakeInstrumental(e.target.value)}
                    label="Song Type"
                  >
                    <MenuItem value={false}>With Lyrics</MenuItem>
                    <MenuItem value={true}>Instrumental</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleCreateSong}
                  disabled={isGeneratingSong || !lyrics}
                >
                  {isGeneratingSong ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Create Song"
                  )}
                </Button>
                {songTaskId && (
                  <Typography variant="body2" style={{ marginTop: 16 }}>
                    Song creation task initiated! Task ID: {songTaskId}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default SongCreationSection;