import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { alpha } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";

import Container from "./Container";

const images = [
  {
    group: [
      {
        cover:
          "https://assets.maccarianagency.com/screenshots/the-front/img1.png",
        coverDark:
          "https://assets.maccarianagency.com/screenshots/the-front/img1--dark.png",
      },
      {
        cover:
          "https://assets.maccarianagency.com/screenshots/the-front/img4.png",
        coverDark:
          "https://assets.maccarianagency.com/screenshots/the-front/img4--dark.png",
      },
    ],
  },
  {
    group: [
      {
        cover:
          "https://assets.maccarianagency.com/screenshots/the-front/img13.png",
        coverDark:
          "https://assets.maccarianagency.com/screenshots/the-front/img13--dark.png",
      },
      {
        cover:
          "https://assets.maccarianagency.com/screenshots/the-front/img10.png",
        coverDark:
          "https://assets.maccarianagency.com/screenshots/the-front/img10--dark.png",
      },
      {
        cover:
          "https://assets.maccarianagency.com/screenshots/the-front/img7.png",
        coverDark:
          "https://assets.maccarianagency.com/screenshots/the-front/img7--dark.png",
      },
    ],
  },
  {
    group: [
      {
        cover:
          "https://assets.maccarianagency.com/screenshots/the-front/img6.png",
        coverDark:
          "https://assets.maccarianagency.com/screenshots/the-front/img6--dark.png",
      },
      {
        cover:
          "https://assets.maccarianagency.com/screenshots/the-front/img24.png",
        coverDark:
          "https://assets.maccarianagency.com/screenshots/the-front/img24--dark.png",
      },
      {
        cover:
          "https://assets.maccarianagency.com/screenshots/the-front/img17.png",
        coverDark:
          "https://assets.maccarianagency.com/screenshots/the-front/img17--dark.png",
      },
      {
        cover:
          "https://assets.maccarianagency.com/screenshots/the-front/img12.png",
        coverDark:
          "https://assets.maccarianagency.com/screenshots/the-front/img12--dark.png",
      },
    ],
  },
];

const HeroSection = () => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Box
        sx={{
          backgroundImage: `linear-gradient(to bottom, ${alpha(
            theme.palette.primary.main,
            0.01
          )}, ${alpha(theme.palette.secondary.main, 0.02)})`,
          // backgroundImage: `linear-gradient(to bottom, ${alpha(
          //   theme.palette.background.paper,
          //   0,
          // )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
          backgroundRepeat: "repeat-x",
          position: "relative",
        }}
      >
        <Box paddingY={{ xs: 0, sm: "4rem", md: "8rem" }}>
          <Container>
            <Box maxWidth={{ xs: 1, sm: "50%" }}>
              <Typography
                variant="h2"
                color="text.primary"
                gutterBottom
                sx={{
                  fontWeight: 700,
                }}
              >
                Craft your ideas
                <br />
                into{" "}
                <Typography
                  color={"primary"}
                  component={"span"}
                  variant={"inherit"}
                  sx={{
                    fontWeight: 900,
                    background: `linear-gradient(180deg, transparent 82%, ${alpha(
                      theme.palette.secondary.main,
                      0.3
                    )} 0%)`,
                  }}
                >
                  success
                </Typography>
              </Typography>
              <Typography
                variant="h6"
                component="p"
                color="text.secondary"
                sx={{ fontWeight: 400 }}
              >
                Making your marketing process more effective. 
                <br/>Future proof your business now.
              </Typography>
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "stretched", sm: "flex-start" }}
                marginTop={4}
              >
                <Button
                  component={"a"}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={isMd ? false : true}
                  href={"/combined_adda"}
                >
                  Get Started
                </Button>
                <Box
                  marginTop={{ xs: 2, sm: 0 }}
                  marginLeft={{ sm: 2 }}
                  width={{ xs: "100%", md: "auto" }}
                >
                  <Button
                    component={"a"}
                    href={"/"}
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth={isMd ? false : true}
                  >
                    Contact Us
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
          <Box
            sx={{
              transform: "rotate(-20deg)",
              display: { xs: "none", sm: "block" },
            }}
          >
            <Box
              display={"flex"}
              width={"50rem"}
              left={"50%"}
              top={0}
              position={"absolute"}
              sx={{ transform: "translate3d(20%, -50%, 0)" }}
            >
              {images.map((item, i) => (
                <Box key={i} marginTop={{ sm: -(i * 16) }} marginX={1}>
                  {item.group.map((g, j) => (
                    <Box
                      key={j}
                      padding={1}
                      bgcolor={"background.paper"}
                      borderRadius={2}
                      boxShadow={3}
                      marginTop={2}
                    >
                      <Box
                        component={"img"}
                        src={
                          theme.palette.mode === "dark" ? g.coverDark : g.cover
                        }
                        height={1}
                        width={1}
                        maxWidth={320}
                      />
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: "100%",
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
