import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Button,
  Grid,
  InputLabel,
  Container,
  Typography,
  Box,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Tabs,
  Tab,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from "../util/auth";
import { Link } from "./../util/router";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import CreatableSelect from "react-select/creatable";
import { retrieveCMLabels, saveCreatedLabel, deleteLabel, retrieveCMS, updateCMS, deleteCMS } from "../util/db";
import { useRefresh } from "../context/RefreshContext";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(5),
    boxShadow: theme.shadows[4],
  },
  input: {
    width: "100%",
  },
  drawerPaper: {
    height: '50%',
    overflow: 'auto',
    position: 'absolute',
    top: '25%', 
  },
}));

const ContentManagementSection = (props) => {
  const classes = useStyles();
  const auth = useAuth();
  const { refresh } = useRefresh();
  const [multiValue, setMultiValue] = useState(null);
  const [savedLabels, setSavedLabels] = useState([]);
  const [labelOptions, setLabelOptions] = useState([]);
  const [cmsData, setCmsData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDeleteLabel = async (label) => {
    await deleteLabel(auth.user.uid, label);
    handleRetrieveLabels();
  };

  const handleMultiChange = async (option) => {
    setMultiValue(option);
  
    // Save the created option to Firebase
    try {
      await saveCreatedLabel(auth.user.uid, option);
      handleRetrieveLabels();
      console.log("Option saved successfully!");
    } catch (error) {
      console.error("Error saving option:", error);
    }
  };

  const handleRetrieveLabels = async () => {
    try {
      const inputs = await retrieveCMLabels(auth.user.uid);
      const formattedInputs = inputs.map((input, index) => ({
        ...input,
      }));
      setSavedLabels(formattedInputs);
    } catch (error) {
      console.error("Error retrieving inputs:", error);
    }
  };

  const handleEditTextChange = (id, value) => {
    setCmsData(cmsData.map(data => data.id === id ? { ...data, field: { ...data.field, edit_text: value } } : data));
  };
  
  const handleRemarksChange = (id, value) => {
    setCmsData(cmsData.map(data => data.id === id ? { ...data, field: { ...data.field, remarks: value } } : data));
  };
  
  const handleLabelChange = (id, value) => {
    setCmsData(cmsData.map(data => data.id === id ? { ...data, field: { ...data.field, label: value.map(v => v.value) } } : data));
  };

  const handleSave = async (id, field) => {
    await updateCMS(auth.user.uid, id, field);
  };
  
  const handleDelete = async (id) => {
    await deleteCMS(auth.user.uid, id);
    setCmsData(cmsData.filter(data => data.id !== id));
  };

  // useEffect for retrieve and set savedLabels 
  useEffect(async () => {
    handleRetrieveLabels();
    retrieveCMS(auth.user.uid).then(setCmsData);
  }, []);

  // useEffect for retrieve and set savedLabels after refresh
  useEffect(() => {
    handleRetrieveLabels();
  }, [refresh]);

  // useEffect to set savedLabels into options
  useEffect(() => {
    setLabelOptions(
      savedLabels
        .filter((input) => input.key === "labels")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
            label: value,
          }));
        })
        .flat()
    );
  }, [savedLabels]);

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5" component="h2">
              Content Management
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={handleDrawerOpen}>Tags</Button>
          </Grid>
        </Grid>

        {cmsData.map((data) => (
          <Grid container={true} spacing={4} justifyContent="center" key={data.id}>
            {/* Left Grid */}
            <Grid item={true} xs={12} md={8}>
              <Box display="flex" flexDirection="column" height="100%">
                <Card style={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h6">
                      文案改写
                    </Typography>
                    <TextField
                      className={classes.input}
                      label="Original"
                      variant="outlined"
                      margin="normal"
                      multiline
                      minRows={1}
                      maxRows={6}
                      InputProps={{ style: { overflowY: 'auto' } }}
                      disabled
                      value={data.field.ori_text}
                    />
                    <TextField
                      className={classes.input}
                      label="Modified"
                      variant="outlined"
                      margin="normal"
                      multiline
                      minRows={1}
                      maxRows={2}
                      InputProps={{ style: { overflowY: 'auto' } }}
                      value={data.field.edit_text}
                      onChange={(e) => handleEditTextChange(data.id, e.target.value)}
                    />
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            {/* Right Grid */}
            <Grid item xs={12} md={4}>
              <Box display="flex" flexDirection="column" height="100%">
                <Card style={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h6">
                      Tags and Remarks
                    </Typography>
                    <CreatableSelect
                      label="Tags"
                      isMulti
                      onChange={(value) => {
                        handleLabelChange(data.id, value);
                        handleMultiChange(value);
                      }}
                      options={labelOptions}
                      value={data.field.label.map(label => ({ value: label, label }))}
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 3 })
                      }}
                    />
                    <TextField
                      className={classes.input}
                      label="Remark"
                      variant="outlined"
                      margin="normal"
                      multiline
                      minRows={3}
                      maxRows={3}
                      value={data.field.remarks}
                      onChange={(e) => handleRemarksChange(data.id, e.target.value)}
                    />
                    <Button onClick={() => handleSave(data.id, data.field)}>Save</Button>
                    <Button onClick={() => handleDelete(data.id)}>Delete</Button>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        ))}
        
      </Container>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose} classes={{ paper: classes.drawerPaper }}>
      <List>
        {savedLabels.map((labelObj) => 
          labelObj.obj.map((label, index) => (
            <ListItem key={index}>
              <ListItemText primary={label} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteLabel(label)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        )}
      </List>
      </Drawer>
    </Section>
  );
};

export default ContentManagementSection;