import antidox from './antidox.webp';
import beansandco from './beansandco.webp';
import best_osteo from './best_osteo.webp';
import big_tooth_school from './big_tooth_school.webp';
import dctb from './dctb.webp';
import dnine from './dnine.webp';
import easystonegold from './easystonegold.webp';
import flexicare from './flexicare.webp';
import footer_socks from './footer_socks.webp';
import freya_beucare from './freya_beucare.webp';
import haloandyou from './haloandyou.webp';
import haruma_senko from './haruma_senko.webp';
import heehoo from './heehoo.webp';
import hishin from './hishin.webp';
import jen_mommy from './jen_mommy.webp';
import kurin_water from './kurin_water.webp';
import rezellegroup from './rezellegroup.webp';
import rosewhite from './rosewhite.webp';
import sederhana_furni from './sederhana_furni.webp';
import sosmoothcream from './sosmoothcream.webp';
import taiyo_healthcare from './taiyo_healthcare.webp';
import topgroup_lifeplanning from './topgroup_lifeplanning.webp';
import vinz_wellness from './vinz_wellness.webp';
import zeero_skincare from './zeero_skincare.webp';

export const logos = [
  { src: antidox, url: 'https://www.facebook.com/glowskinwithnn' },
  { src: beansandco, url: 'https://www.facebook.com/BeansCOArtisanalChocolate' },
  { src: best_osteo, url: 'https://www.facebook.com/bestosteomy' },
  { src: big_tooth_school, url: 'https://www.facebook.com/colourme.art.atelier' },
  { src: dctb, url: 'https://www.facebook.com/dctbfronttechnology' },
  { src: dnine, url: 'https://www.facebook.com/dnineofficialpage1' },
  { src: easystonegold, url: 'https://www.facebook.com/EasystoneGold' },
  { src: flexicare, url: 'https://www.facebook.com/profile.php?id=100076291241129' },
  { src: footer_socks, url: 'https://www.facebook.com/footertaiwan' },
  { src: freya_beucare, url: 'https://www.facebook.com/freya14134/' },
  { src: haloandyou, url: 'https://www.facebook.com/haloandyoumy' },
  { src: haruma_senko, url: 'https://www.facebook.com/harumasenko' },
  { src: heehoo, url: 'https://www.facebook.com/jklapparel/' },
  { src: hishin, url: 'https://shopee.com.my/hishin_malaysia' },
  { src: jen_mommy, url: 'https://www.facebook.com/jenmommyjingyou' },
  { src: kurin_water, url: 'https://shopee.com.my/kurin_malaysia' },
  { src: rezellegroup, url: 'https://www.facebook.com/ReZelle.Official/' },
  { src: rosewhite, url: 'https://www.facebook.com/rosewhitehq' },
  { src: sederhana_furni, url: 'https://www.facebook.com/serdehanaonlineperabot' },
  { src: sosmoothcream, url: 'https://www.facebook.com/SoSmoothCream' },
  { src: taiyo_healthcare, url: 'https://www.facebook.com/SoSmoothCream' },
  { src: topgroup_lifeplanning, url: 'https://www.facebook.com/tglifeplanner/' },
  { src: vinz_wellness, url: 'https://www.facebook.com/VinzWellnessOralSpray' },
  { src: zeero_skincare, url: 'https://www.facebook.com/Zeero.Skincare/' }
];