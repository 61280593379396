import React from "react";
import Meta from "../components/Meta";
import { requireProUser } from "../util/auth";
import CombinedADDASection from "../components/CombinedADDASection";

function CombinedADDAPage(props) {
  return (
    <>
      <Meta title="Combined ADDA" />
      <CombinedADDASection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Combined ADDA"
        subtitle="ADDA整合"
      />
    </>
  );
}

export default requireProUser(CombinedADDAPage);