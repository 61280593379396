import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import CreatableSelect from "react-select/creatable";
import { useAuth } from "../util/auth";
import { getFirestore } from "firebase/firestore";
import { firebaseApp } from "../util/firebase";
import { useRefresh } from "../context/RefreshContext";
import Section from "./Section";
import { retrieveInputs, createProfile, retrieveProfiles, saveProfileData, loadProfileData } from "../util/db";
import { Toaster, toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "950px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  truncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "500px",
  },
}));

const SavedProfileSection = (props) => {
  const classes = useStyles();
  const { refresh } = useRefresh();
  const { t, i18n } = useTranslation();
  const auth = useAuth();
  const db = getFirestore(firebaseApp);

  // input declaration
  const [productNamelegacy, setProductNamelegacy] = useState([]);
  const [productUSPlegacy, setProductUSPlegacy] = useState([]);
  const [productContentlegacy, setProductContentlegacy] = useState([]);
  const [productCertlegacy, setProductCertlegacy] = useState([]);
  const [productIngredientlegacy, setProductIngredientlegacy] = useState([]);
  const [painPointLegacy, setPainPointLegacy] = useState("");
  const [callToActionLegacy, setCallToActionLegacy] = useState("");
  const [introduceLegacy, setIntroduceLegacy] = useState("");

  const [savedInputs, setSavedInputs] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [uspOptions, setUSPOptions] = useState([]);
  const [contentOptions, setContentOptions] = useState([]);
  const [certOptions, setCertOptions] = useState([]);
  const [ingredientOptions, setIngredientOptions] = useState([]);
  
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    retrieveProfiles(auth.user.uid).then(setProfiles);
  }, [auth.user.uid]);

  const handleCreateProfile = async (inputValue) => {
    const newProfile = await createProfile(auth.user.uid, inputValue);
    setProfiles([...profiles, newProfile]);
    setSelectedProfile(newProfile);
  
    setProductNamelegacy("");
    setProductUSPlegacy("");
    setProductContentlegacy("");
    setProductCertlegacy("");
    setProductIngredientlegacy("");
    setPainPointLegacy("");
    setCallToActionLegacy("");
    setIntroduceLegacy("");
  };

const handleSelectProfile = async (profile) => {
  setSelectedProfile(profile);
  const profileData = await loadProfileData(auth.user.uid, profile.value);
  if (profileData) {
    setProductNamelegacy(profileData.productName || "");
    setProductUSPlegacy(profileData.productUSP || "");
    setProductContentlegacy(profileData.productContent || "");
    setProductCertlegacy(profileData.productCert || "");
    setProductIngredientlegacy(profileData.productIngredient || "");
    setPainPointLegacy(profileData.painPoint || "");
    setCallToActionLegacy(profileData.callToAction || "");
    setIntroduceLegacy(profileData.introduce || "");
  }
};

const handleSave = async () => {
  if (selectedProfile) {
    const data = {
      productName: productNamelegacy,
      productUSP: productUSPlegacy,
      productContent: productContentlegacy,
      productCert: productCertlegacy,
      productIngredient: productIngredientlegacy,
      painPoint: painPointLegacy,
      callToAction: callToActionLegacy,
      introduce: introduceLegacy
    };
    await saveProfileData(auth.user.uid, selectedProfile.value, data);
    toast.success("Profile saved successfully!");
  }
};

  // Handles the retrieval of savedInputs
  const handleRetrieveInputs = async () => {
    try {
      const inputs = await retrieveInputs(auth.user.uid);
      const formattedInputs = inputs.map((input, index) => ({
        ...input,
      }));
      setSavedInputs(formattedInputs);
    } catch (error) {
      console.error("Error retrieving inputs:", error);
    }
  };

  // useEffect for retrieve and set savedInputs after refresh
  useEffect(() => {
    handleRetrieveInputs();
  }, [refresh]);

  // useEffect to set savedInputs into options
  useEffect(() => {
    setNameOptions(
      savedInputs
        .filter((input) => input.key === "product_name")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
        .filter(option => option.value)
    );

    setUSPOptions(
      savedInputs
        .filter((input) => input.key === "usp")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
        .filter(option => option.value)
    );

    setContentOptions(
      savedInputs
        .filter((input) => input.key === "content_angle")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
        .filter(option => option.value)
    );

    setIngredientOptions(
      savedInputs
        .filter((input) => input.key === "product_ingredient")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
        .filter(option => option.value)
    );

    setCertOptions(
      savedInputs
        .filter((input) => input.key === "product_cert")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
        .filter(option => option.value)
    );
  }, [savedInputs]);

  return (
    <>
      <Container>
        <Typography variant="h5" style={{ fontWeight: 'bold', marginLeft: '25px' }}> Edit Saved Brand Profile </Typography>
        <Toaster position="bottom-right" reverseOrder={false} />
        <Box display="flex" flexDirection="row" justifyContent="space-between" py={2} px={3} flex={1}>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <CreatableSelect
                isClearable
                isCreatable
                placeholder="Select or create..."
                options={profiles}
                value={selectedProfile}
                onChange={handleSelectProfile}
                onCreateOption={handleCreateProfile}
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    fontSize: '1rem',
                  }),
                  option: (base) => ({
                    ...base,
                    color: 'black',
                    fontSize: '1rem',
                  }),
                  placeholder: (base) => ({
                    ...base,
                    fontSize: '1rem',
                  }),
                  singleValue: (base) => ({
                    ...base,
                    fontSize: '1rem',
                  }),
                }}
              />
              <Typography variant="body2" style={{ marginLeft: 8 }}>
                要创建新的配置，只需输入新名称并按 Enter 键即可。
              </Typography>
              <Typography variant="body2" style={{ marginLeft: 8 }}>
                To create a new profile, simply enter a new name and hit Enter.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button onClick={handleSave} variant="contained" color="primary" style={{ fontSize: '1rem' }}>Save</Button>
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} flex={1}>
          <Typography component="span">
            Product Name / Brand
          </Typography>
          <Autocomplete
            freeSolo
            options={nameOptions.map((option) => option.value)}
            value={productNamelegacy}
            onChange={(event, newValue) => {
              setProductNamelegacy(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setProductNamelegacy(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                placeholder={t("CPW001") + t("CPW006")}
                multiline
                minRows={2}
                maxRows={2}
              />
            )}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} flex={1}>
          <Typography component="span">
            USP
          </Typography>
          <Autocomplete
            freeSolo
            options={uspOptions.map((option) => option.value)}
            value={productUSPlegacy}
            onChange={(event, newValue) => {
              setProductUSPlegacy(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setProductUSPlegacy(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                placeholder={t("CPW001") + t("CPW015")}
                multiline
                minRows={2}
                maxRows={2}
              />
            )}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} flex={1}>
          <Typography component="span">
            Content Angle
          </Typography>
          <Autocomplete
            freeSolo
            options={contentOptions.map((option) => option.value)}
            value={productContentlegacy}
            onChange={(event, newValue) => {
              setProductContentlegacy(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setProductContentlegacy(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                placeholder={t("CPW001") + t("CPW016")}
                multiline
                minRows={2}
                maxRows={2}
              />
            )}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} flex={1}>
          <Typography component="span">
            Product Certification
          </Typography>
          <Autocomplete
            freeSolo
            options={certOptions.map((option) => option.value)}
            value={productCertlegacy}
            onChange={(event, newValue) => {
              setProductCertlegacy(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setProductCertlegacy(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                placeholder={t("CPW001") + t("CPW023")}
                multiline
                minRows={2}
                maxRows={2}
              />
            )}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} flex={1}>
          <Typography component="span">
            Product Ingredient
          </Typography>
          <Autocomplete
            freeSolo
            options={ingredientOptions.map((option) => option.value)}
            value={productIngredientlegacy}
            onChange={(event, newValue) => {
              setProductIngredientlegacy(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setProductIngredientlegacy(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                placeholder={t("CPW001") + t("CPW022")}
                multiline
                minRows={2}
                maxRows={2}
              />
            )}
          />
        </Box>
        <Box display="flex" flexDirection="column" py={2} px={3} flex={1}>
        <Typography component="span">
          Pain Point
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={painPointLegacy}
          onChange={(e) => setPainPointLegacy(e.target.value)}
          placeholder={t("CPW001") + t("CPW052")}
          multiline
          minRows={1}
          maxRows={1}
        />
      </Box>

      <Box display="flex" flexDirection="column" py={2} px={3} flex={1}>
        <Typography component="span">
          Call to Action
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={callToActionLegacy}
          onChange={(e) => setCallToActionLegacy(e.target.value)}
          placeholder={t("CPW001") + t("CPW106")}
          multiline
          minRows={2}
          maxRows={2}
        />
      </Box>

      <Box display="flex" flexDirection="column" py={2} px={3} flex={1}>
        <Typography component="span">
          Introduction
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={introduceLegacy}
          onChange={(e) => setIntroduceLegacy(e.target.value)}
          placeholder={t("CPW001") + t("CPW107")}
          multiline
          minRows={2}
          maxRows={2}
        />
      </Box>

      </Container>
    </>
  );
};

export default SavedProfileSection;