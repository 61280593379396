import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from './Container';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { logos } from '../assets/webp/landing_page_logo';

const CustomerLogoGrid = () => {
  const theme = useTheme();

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 10000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    rows: 2,
    slidesPerRow: 1,
    pauseOnHover: true,
  };

  return (
    <Container marginTop={4} marginBottom={4} borderRadius={10}>
      <Slider {...settings}>
        {logos.map((logo, i) => (
          <Box key={i} p={0}>
            <Box
              bgcolor={'alternate.main'}
              borderRadius={2}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              p={2}
            >
              <a href={logo.url} target="_blank" rel="noopener noreferrer">
                <img
                  src={logo.src}
                  alt={`logo-${i}`}
                  style={{
                    maxWidth: '140px',
                    maxHeight: '140px',
                    filter: theme.palette.mode === 'dark' ? 'brightness(0) invert(1)' : 'none',
                  }}
                />
              </a>
            </Box>
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default CustomerLogoGrid;