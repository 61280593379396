import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import CombinedADDASection from "../../components/eng/CombinedADDASection";

function CombinedADDAPage(props) {
  return (
    <>
      <Meta title="Combined ADDA (English)" />
      <CombinedADDASection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Combined ADDA (英文)"
        subtitle="ADDA整合 (英文)"
      />
    </>
  );
}

export default requireProUser(CombinedADDAPage);