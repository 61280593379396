import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Button,
  Box,
  FormLabel,
  Card,
} from "@material-ui/core";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  getDocs,
  serverTimestamp
} from "firebase/firestore";
import { firebaseApp } from "../../util/firebase";
import { useAuth } from "../../util/auth";
import { CircularProgress } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "15%",
    minWidth: 120,
  },
  searchControl: {
    margin: theme.spacing(1),
    width: "50%",
    minWidth: 360,
  },
  searchButton: {
    backgroundColor: "#3b5998",
    color: "white",
    "&:hover": {
      backgroundColor: "blue",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height:"20%",
    marginTop: "1rem",
    marginBottom: "1rem",
    padding: theme.spacing(2),
    minWidth: 100,
    borderRadius: 100,
    // fontSize: '1rem',
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "1rem",
  },
  searchBarLabel: {
    marginBottom: "0.5rem",
  },
  selectInput: {
    height: 40, 
    '& .MuiSelect-select': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
    }
  },
}));

const AdLibraryFilter = ({
  onSearchClick,
  selectedCards,
  saveMedia,
  onFilterChange,
  onFilterResultsChange,
  clearData,
  onClearSelection,
  mediaType,
  language,
  searchQuery,
  onMediaChange,
  onLanguageChange,
  onSearchQueryChange,
  onDownloadMedia,
  isLoading,
}) => {
  const classes = useStyles();
  const auth = useAuth();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [dataArray, setDataArray] = useState([]);
  const [usedIndexes, setUsedIndexes] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [filterValue, setFilterValue] = useState("All");

  return (
    <Box style={{ width: "100%" }}>
      <Box className={classes.container}>

        {/* Country */}
        <FormControl className={classes.formControl} disabled>
          <FormLabel
            component="legend"
            id="country-label"
            className={classes.searchBarLabel}
          >
            Country
          </FormLabel>
          <Select
            className={classes.selectInput}
            labelid="country-label"
            id="country-select"
            key="country-select"
            value="Malaysia"
            variant="outlined"
          >
            <MenuItem value="Malaysia">Malaysia</MenuItem>
          </Select>
        </FormControl>

        {/* Active Ads */}
        <FormControl className={classes.formControl} disabled>
          <FormLabel
            component="legend"
            id="category-label"
            className={classes.searchBarLabel}
          >
            Ad Type
          </FormLabel>
          <Select
            className={classes.selectInput}
            labelid="category-label"
            id="category-select"
            key="category-select"
            value="Active ads"
            variant="outlined"
          >
            <MenuItem value="Active ads">Active ads</MenuItem>
          </Select>
        </FormControl>

        {/* Language */}
        <FormControl className={classes.formControl}>
          <FormLabel
            component="legend"
            id="language-label"
            className={classes.searchBarLabel}
          >
            Language
          </FormLabel>
          <Select
            className={classes.selectInput}
            labelid="language-label"
            id="language-select"
            key="language-select"
            value={language  || "english"}
            onChange={onLanguageChange}
            variant="outlined"
          >
            <MenuItem value="chinese">Chinese</MenuItem>
            <MenuItem value="english">English</MenuItem>
            <MenuItem value="malay">Malay</MenuItem>
          </Select>
        </FormControl>

        {/* Media Type */}
        <FormControl className={classes.formControl}>
          <FormLabel
            component="legend"
            id="media-label"
            className={classes.searchBarLabel}
          >
            Media Type
          </FormLabel>
          <Select
            className={classes.selectInput}
            labelid="media-label"
            id="media-select"
            key="media-select"
            value={mediaType}
            onChange={onMediaChange}
            variant="outlined"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="imageText">Image</MenuItem>
            <MenuItem value="video">Video</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box className={classes.searchContainer}>
        {/* Search */}
        <FormControl className={classes.searchControl}>
          <FormLabel
            component="legend"
            id="text-search-label"
            className={classes.searchBarLabel}
          >
            Search
          </FormLabel>
          <TextField
            className={classes.selectInput}
            id="text-search-input"
            labelid="text-search-label"
            variant="outlined"
            value={searchQuery}
            onChange={onSearchQueryChange}
          />
        </FormControl>
      </Box>
      <Box>
      <Button
          onClick={onSearchClick}
          disabled={
            isLoading ||
            searchQuery === ""
          }
          variant="contained"
          className={classes.searchButton}
        >
          {isLoading ? (
            <CircularProgress size={24} color="secondary" />
          ) : (
            "Search (0.5)"
          )}
        </Button>
        {selectedCards.length > 0 && (
          <>
            <Button
              variant="contained"
              className={classes.searchButton}
              onClick={() => {
                // console.log(selectedCards)
                saveMedia();
              }}
            >
              Save Media
            </Button>

            <Button
              variant="contained"
              className={classes.searchButton}
              onClick={onDownloadMedia}
            >
              <GetAppIcon />
            </Button>

            <Button
              variant="contained"
              className={classes.searchButton}
              onClick={onClearSelection}
            >
              Clear
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AdLibraryFilter;
